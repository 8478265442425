/****************************************
	UI
*****************************************/
($ => {
	if (window.ui) {
		ui.warn('ERROR: You are including ui.js multiple times');
		return;
	}
	class ThrottledFn {
		/**
		 * @param {function} callback
		 * @param {number} delay
		 * @param {Object} options
		 * @param {boolean} options.leading - Fires callback on the leading edge of throttle
		 * @param {boolean} options.trailing - Fires callback on trailing edge of throttle.
		 * @param {boolean} options.debounce - Toggles debounce functionality
		 */
		constructor(callback, delay, options) {
			this.callback = callback;
			this.delay = delay;
			this.config = Object.assign({ leading: true, trailing: true }, options);
			this.lastInvoke = 0;
			this.lastCall = 0;
			this.invokeCallback = this.invokeCallback.bind(this);
		}
		cancelTimer() {
			clearTimeout(this.timerId);
			this.timerId = undefined;
		}
		clearArguments() {
			this.lastArgs = undefined;
		}
		execute(args) { // Entry point for throttled methods
			const { leading, trailing } = this.config;
			if (!leading && !trailing) {
				ui.warn('You must have either a trailing or leading enabled in your throttle/debounce');
				return;
			}
			const time = Date.now();
			this.lastArgs = args;
			this.leadingEdge(time);
			this.lastCall = time;
			this.trailingEdge();
		}
		inProgress() {
			return this.timerId !== undefined;
		}
		invokeCallback(time) {
			this.lastInvoke = time;
			this.callback(...this.lastArgs);
			this.clearArguments();
		}
		leadingEdge(time) {
			if (this.shouldInvokeLeading(time)) {
				this.invokeCallback(time);
			}
		}
		reset() {
			this.cancelTimer();
			this.clearArguments();
			this.lastCall = 0;
			this.lastInvoke = 0;
		}
		shouldInvokeLeading(time) {
			const { leading, debounce } = this.config;
			if (!leading) {
				return false;
			}
			const sinceLastCall = time - this.lastCall;
			const sinceLastInvoke = time - this.lastInvoke;
			const isLastCallWithinDelay = sinceLastCall >= this.delay;
			const isLastInvokeWithinDelay = sinceLastInvoke >= this.delay || sinceLastInvoke === 0;
			return debounce ? isLastCallWithinDelay : isLastInvokeWithinDelay;
		}
		shouldInvokeTrailing() {
			const { trailing, debounce } = this.config;
			return trailing && (debounce || !this.inProgress());
		}
		startTimer(timerFunc) {
			if (this.config.debounce) {
				this.cancelTimer();
			}
			return setTimeout(timerFunc.bind(this), this.delay);
		}
		trailingEdge() {
			if (this.shouldInvokeTrailing()) {
				this.timerId = this.startTimer(this.timerExpired);
			}
		}
		timerExpired() {
			const noTrailingCall = this.lastCall === this.lastInvoke;
			if (noTrailingCall) {
				return;
			}
			this.invokeCallback(Date.now());
			this.reset();
		}
	}
	window.ui = {
		debounce(callback, delay, immediate = false) {
			return this._throttle(callback, delay, { leading: immediate, trailing: true, debounce: true });
		},
		deleteCookie(cookieName) {
			document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC`;
		},
		escapeRegExp(str) {
			return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
		},
		focusable(element, isTabIndexNotNaN) {
			const nodeName = element.nodeName.toLowerCase();
			let cond;
			if (/input|select|textarea|button|object/.test(nodeName)) {
				cond = !element.disabled;
			} else {
				cond = nodeName === 'a' ? element.href || isTabIndexNotNaN : isTabIndexNotNaN;
			}
			return cond && this.visible(element);
		},
		getCookie(name) {
			const cookieName = `${name}=`;
			const cookies = document.cookie.split(';');
			const len = cookies.length;
			let cookie;
			for (let i = 0; i < len; i++) {
				cookie = cookies[i].trim();
				if (cookie.indexOf(cookieName) === 0) {
					return cookie.substring(cookieName.length, cookie.length);
				}
			}
			return '';
		},
		getDictionaryCookie(cookieName, entryName) {
			const cookie = this.getCookie(cookieName);
			if (!cookie.length) {
				return '';
			}
			const values = cookie.split('&');
			const entry = `${entryName}=`;
			for (let i = values.length - 1; i >= 0; i--) {
				if (values[i].indexOf(entry) !== -1) {
					return values[i].substr(values[i].indexOf(entry) + entry.length);
				}
			}
			return '';
		},
		getRandomNumber(min, max) {
			if (!isNaN(min) && !isNaN(max)) {
				return Math.floor((Math.random() * (1 + max - min)) + min);
			}
			return Date.now().toString() + Math.floor((Math.random() * 1000) + 1);
		},
		getTabbableElements($container) {
			return $container
				.find(this.tabbableTags)
				.filter((i, ele) => this.tabbable(ele));
		},
		getUrlParameterValue(param, url, caseSensitive) {
			/* Get the text between the first ? and the either the first # or the end of the url */
			const query = url ? (url.split(/\?(.+)?/)[1] || '').split(/#(.+)?/)[0] : window.location.search.substring(1);
			const vars = query.replace(/&amp;/g, '&').split('&');
			const len = vars.length;
			let modifiedParam = param;
			for (let i = 0; i < len; i++) {
				const pair = vars[i].split('=');
				if (!caseSensitive) {
					modifiedParam = modifiedParam.toLowerCase();
					pair[0] = pair[0].toLowerCase();
				}
				if (decodeURIComponent(pair[0]) === modifiedParam) {
					return pair[1] ? decodeURIComponent(pair[1]) : '';
				}
			}
			return undefined;
		},
		isNumber(n) {
			return !isNaN(parseFloat(n)) && isFinite(n);
		},
		isValidCallback(callback) {
			return callback && $.isFunction(callback);
		},
		keyCodes: {
			down: 40,
			enter: 13,
			esc: 27,
			left: 37,
			leftBracket: 219,
			right: 39,
			rightBracket: 221,
			shift: 16,
			tab: 9,
			up: 38
		},
		lang: (() => {
			const lang = document.querySelector('[lang]');
			return lang ? lang.getAttribute('lang').toLowerCase() : 'en';
		})(),
		localize(langSet, key) {
			if (langSet[this.lang] && langSet[this.lang][key]) {
				return langSet[this.lang][key];
			}
			if (langSet[this.lang.substring(0, 2)] && langSet[this.lang.substring(0, 2)][key]) {
				return langSet[this.lang.substring(0, 2)][key];
			}
			return langSet.en[key] || '';
		},
		numberToLocaleString(val, options) {
			try {
				return val.toLocaleString(this.lang, options);
			} catch (e) {
				/* If the browser doesn't support the latest 'toLocaleString', fallback to the browser */
				return val.toLocaleString();
			}
		},
		setCookie(cookieName, value, daysUntilExpiration = 90, domain = window.location.hostname.replace(/.+?\./, '.'), path = '/') {
			const d = new Date();
			const secondsInDay = 24 * 60 * 60 * 1000;
			d.setTime(d.getTime() + (daysUntilExpiration * secondsInDay));
			const expires = `expires=${d.toUTCString()}`;
			document.cookie = `${cookieName}=${value}; ${expires};domain=${domain};path=${path}`;
		},
		setDictionaryCookie(cookieName, keyName, value, daysUntilExpiration, domain, path) {
			const name = cookieName.toUpperCase();
			const key = keyName.toUpperCase();
			let dictionary = this.getCookie(name);
			if (dictionary === null || dictionary === undefined) {
				dictionary = `${key}=${value}`;
			} else {
				let dictSrc = dictionary.toLowerCase();
				const startKey = dictSrc.indexOf(`${key.toLowerCase()}=`);
				if (startKey === -1) {
					/* key was not there */
					if (dictionary.length > 0) {
						dictionary = `${dictionary}&`;
					}
					dictionary = `${dictionary}${key}=${value}`;
				} else {
					const endKey = dictSrc.indexOf('&', startKey);
					if (endKey === -1) {
						/* no keys after */
						if (startKey === 0) {
							/* no keys before and no keys after */
							dictionary = `${key}=${value}`;
						} else {
							/* keys before not after */
							dictionary = `${dictionary.substring(0, startKey)}${key}=${value}`;
						}
					} else {
						/* key is somewhere in middle */
						dictSrc = dictionary;
						dictionary = '';
						if (startKey > 0) {
							/* keys before */
							dictionary = dictSrc.substring(0, startKey);
						}
						dictionary = `${dictionary}${key}=${value}${dictSrc.substring(endKey)}`;
					}
				}
			}
			this.setCookie(name, dictionary, daysUntilExpiration, domain, path);
		},
		tabbableTags: '[tabindex], a, button, input, object, select, textarea',
		tabbable(element) {
			const tabIndex = $.attr(element, 'tabindex');
			const isTabIndexNaN = isNaN(tabIndex);
			return (isTabIndexNaN || tabIndex >= 0) && this.focusable(element, !isTabIndexNaN);
		},
		_throttle(callback, delay, options) {
			const instance = new ThrottledFn(callback, delay, options);
			const execute = (...args) => instance.execute(args);
			execute.cancel = () => instance.cancelTimer(); // Expose cancelTimer method on throttled function
			execute.isThrottled = true;
			return execute;
		},
		throttle(callback, delay, noTrail = false, leading = true) {
			return this._throttle(callback, delay, { leading, trailing: !noTrail });
		},
		timer(callback, delay) {
			let timerId;
			let	remaining = delay;
			let	start;
			let	state = 'stopped';
			const obj = {
				pause() {
					if (state === 'play') {
						clearTimeout(timerId);
						remaining -= new Date() - start;
						state = 'pause';
					}
				},
				stop() {
					if (state !== 'stopped') {
						clearTimeout(timerId);
						remaining = delay;
						state = 'stopped';
					}
				},
				play() {
					if (state !== 'play') {
						start = new Date();
						clearTimeout(timerId);
						timerId = setTimeout(() => {
							callback();
							remaining = delay;
						}, remaining);
					}
					state = 'play';
				}
			};
			obj.play();
			return obj;
		},
		ucfirst(str) {
			return `${str.charAt(0).toUpperCase()}${str.substr(1).toLowerCase()}`;
		},
		visible(element) {
			const cond1 = $.expr.filters.visible(element);
			const cond2 = !$(element).parents().addBack()
				.filter((i, ele) => $.css(ele, 'visibility') === 'hidden').length;
			return cond1 && cond2;
		},
		warn(msg, useAlert) {
			if (window.console) {
				console.warn(msg); // eslint-disable-line
				if (useAlert && (/ancestry(?:stage|dev)/).test(window.location.hostname)) {
					window.alert(msg); // eslint-disable-line
				}
			}
		}
	};
})(jQuery);

/****************************************
	Alert
*****************************************/
($ => {
	if ($.alert) {
		ui.warn('ERROR: You are including alert.js multiple times');
		return;
	}
	const alert = {
		create($element, options) {
			let instance = $element.data('alertInstance');
			if (instance) {
				instance.destroy();
			}
			instance = Object.create(this.proto);
			instance.$element = $element;
			instance.config = Object.assign({}, this.staticProperties.defaults, instance._getOptionsByClass(), options);
			instance.uniqueId = $element.attr('id') || `alert-${ui.getRandomNumber()}`;
			instance.shouldAnimate = instance.config.animation === true && (($('body').find($element).length && !ui.visible($element[0])) || !instance.config.open);
			instance.animateDuration = 410;
			instance.hasFocusableChildren = instance._hasTabbableElements();
			$element.attr(instance._getAttributes());
			instance._initClasses();
			instance.isOpen = false;
			instance.marginTop = instance._getAlertMargin('top');
			instance.marginBottom = instance._getAlertMargin('bottom');
			if (instance.config.display === 'notification') {
				instance.shouldAnimate = instance.config.animation !== false;
				instance._addOrRemoveNotification();
				instance.config.closeable = true;
				instance.config.removable = true;
			} else if (instance.config.display === 'overlay') {
				instance.center();
				$(window).on(`resize.alert.${instance.uniqueId} orientationchange.alert.${instance.uniqueId}`, ui.debounce(() => {
					instance.center();
				}, 100));
				instance.config.closeable = true;
			}
			if (instance.config.open) {
				instance.open();
			}
			if (instance.config.closeable) {
				instance._createCloseButton();
			}
			$element.data('alertInstance', instance);
			return instance;
		},
		proto: {
			_addOrRemoveNotification() {
				if (!$('#alertNotifications').length) {
					$('<div id="alertNotifications" class="alertNotifications" aria-live="polite"></div>').appendTo('body');
				}
				if (!this.$element.parents('#alertNotifications').length) {
					this.$element.prependTo('#alertNotifications');
				}
			},
			center() {
				if (this.config.display !== 'overlay') {
					return;
				}
				this.$element.css({
					display: 'inline-block',
					position: 'relative',
					width: 'auto'
				});
				const width = Math.ceil(this.$element[0].getBoundingClientRect().width);
				this.$element.css({
					display: '',
					'margin-left': `-${Math.floor(width / 2)}px`,
					position: '',
					width
				});
			},
			close() {
				if (!this.isOpen) {
					return;
				}
				this.$element.trigger('closing.alert');
				if (this.config.animation) {
					const marginShouldAnimate = this.$element.hasClass('alertSitewide') || this.$element.is(':first-child');
					const isContextualTopAlert = this.$element.hasClass('alertContextualTop');
					this.$element.addClass('alertHiding');
					if (isContextualTopAlert) {
						this.$element.css('margin-top', this.marginTop - this.$element.outerHeight(marginShouldAnimate));
					} else {
						this.$element.css('margin-bottom', this.marginBottom - this.$element.outerHeight(marginShouldAnimate));
					}
					setTimeout(() => this._closed(), this.animateDuration);
				} else {
					this._closed();
				}
			},
			_closed() {
				this.$element
					.addClass('alertHidden')
					.css('margin-bottom', '');
				if (ui.isValidCallback(this.config.onClose)) {
					this.config.onClose.apply(this.$element, [this.$element]);
				}
				if (this.durationTimer) {
					this.durationTimer.stop();
				}
				this.isOpen = false;
				this.shouldAnimate = this.config.animation;
				this.$element.trigger('closed.alert');
				if (this.config.removable) {
					this.destroy();
				}
			},
			_createCloseButton() {
				let $closeBtn = this.$element.find('.alertCloseBtn');
				if ($closeBtn.length) {
					$closeBtn
						.addClass('closeBtn')
						.empty();
				} else {
					$closeBtn = $(`<button class="closeBtn alertCloseBtn" type="button" aria-label="${ui.localize(alert.staticProperties.langSets, 'closeBtn')}"></button>`);
					this.$element.append($closeBtn);
				}
				this.$element.addClass('alertCloseable');
				$closeBtn.on('click.alert', () => {
					this.close();
				});
			},
			destroy() {
				$(window).off(`.alert.${this.uniqueId}`);
				if (this.config.removable) {
					this.$element.remove();
				} else {
					this.$element
						.off('.alert')
						.removeData('alertInstance')
						.removeClass('alertCloseable alertContextualBottom alertContextualTop alertInfo alertNotification alertOverlay alertSection alertSitewide alertSlim alertSuccess')
						.find('.closeBtn')
						.remove();
				}
			},
			_getAttributes() {
				const attr = {
					role: this.$element.attr('role'),
					'aria-label': this.$element.attr('aria-label')
				};
				if (!this.$element.attr('role')) {
					if (this.hasFocusableChildren) {
						attr.role = 'alertdialog';
						attr.tabindex = this.$element.attr('tabindex') || '-1';
					} else if (this.config.type === 'warning' || this.config.type === 'success') {
						attr.role = 'alert';
					} else {
						attr.role = 'status';
					}
				}
				if (!this.$element.attr('aria-label')) {
					attr['aria-label'] = this.$element.contents().first().text();
				}
				return attr;
			},
			_getAlertMargin(position) {
				const alertMargin = parseInt(this.$element.css(`margin-${position}`), 10);
				return isNaN(alertMargin) ? 0 : alertMargin;
			},
			_getOptionsByClass() {
				const options = {};
				if (this.$element.hasClass('alertSuccess')) {
					options.type = 'success';
				} else if (this.$element.hasClass('alertInfo') || this.$element.hasClass('alertNote')) {
					options.type = 'info';
				}
				if (this.$element.hasClass('alertSitewide')) {
					options.display = 'sitewide';
				} else if (this.$element.hasClass('alertNotification')) {
					options.display = 'notification';
				} else if (this.$element.hasClass('alertSection')) {
					options.display = 'section';
				} else if (this.$element.hasClass('alertOverlay')) {
					options.display = 'overlay';
				}
				if (this.$element.hasClass('alertContextualBottom')) {
					options.location = 'bottomRight';
				} else if (this.$element.hasClass('alertContextualTop')) {
					options.location = 'topRight';
				}
				if (this.$element.hasClass('alertSlim')) {
					options.size = 'slim';
				}
				if (this.$element.hasClass('alertHidden')) {
					options.open = false;
				}
				if (this.$element.hasClass('alertCloseable')) {
					options.closeable = true;
				}
				return options;
			},
			_hasTabbableElements() {
				const $elem = $(this.$element);
				let	tabElems = 0;
				$elem.children().each((i, el) => {
					if (ui.tabbable(el) && !$(el).hasClass('alertCloseBtn')) {
						tabElems += 1;
					}
				});
				return tabElems > 0;
			},
			_initClasses() {
				this.$element
					.addClass('alert')
					.removeClass('alertCloseable alertInfo alertNotification alertOverlay alertSection alertSitewide alertSlim alertSuccess');
				const configDisplay = {
					notification: 'alertNotification',
					overlay: 'alertOverlay',
					section: 'alertSection',
					sitewide: 'alertSitewide'
				};
				if (configDisplay[this.config.display]) {
					this.$element.addClass(configDisplay[this.config.display]);
				}
				const configLocation = {
					bottomRight: 'alertContextualBottom',
					topRight: 'alertContextualTop'
				};
				if (configLocation[this.config.location]) {
					this.$element.addClass(configLocation[this.config.location]);
				}
				const configType = {
					success: 'alertSuccess',
					info: 'alertInfo',
					note: 'alertInfo'
				};
				if (configType[this.config.type]) {
					this.$element.addClass(configType[this.config.type]);
				}
				if (this.config.closeable === true) {
					this.$element.addClass('alertCloseable');
				}
				if (this.config.size === 'slim') {
					this.$element.addClass('alertSlim');
				}
				if (!this.config.open) {
					this.$element.addClass('alertHidden alertHiding');
				} else if (this.config.open) {
					this.$element.removeClass('alertHidden alertHiding');
				}
			},
			open() {
				if (this.isOpen) {
					return; /* don't run the method if the alert is already open */
				}
				this.$element.trigger('opening.alert');
				if (this.shouldAnimate) {
					const marginToHideAlert = this.$element.removeClass('alertHidden').outerHeight(false);
					const isContextualTopAlert = this.$element.hasClass('alertContextualTop');
					this.$element.addClass('alertNoTransition');
					if (isContextualTopAlert) {
						this.$element.css('margin-top', this.marginTop - marginToHideAlert);
					} else {
						this.$element.css('margin-bottom', this.marginBottom - marginToHideAlert);
					}
					setTimeout(() => {
						this.$element
							.removeClass('alertNoTransition alertHiding')
							.css({
								'margin-bottom': '',
								'margin-top': ''
							});
					}, 10);
					setTimeout(() => this._opened(), this.animateDuration);
				} else {
					this.$element.removeClass('alertHidden alertHiding');
					this._opened();
				}
			},
			_opened() {
				if (this.hasFocusableChildren) {
					this.$element.focus();
				}
				if (this.config.duration > 0) {
					this.durationTimer = ui.timer(() => {
						this.close();
					}, this.config.duration);
					this.$element
						.on('mouseenter.alert', () => {
							this.durationTimer.pause();
						})
						.on('mouseleave.alert', () => {
							this.durationTimer.play();
						});
				}
				if (ui.isValidCallback(this.config.onOpen)) {
					this.config.onOpen.apply(this.$element, [this.$element]);
				}
				this.isOpen = true;
				this.$element.trigger('opened.alert');
			}
		},
		staticProperties: {
			defaults: {
				animation: true, /* Whether or not to animate in the alert or not */
				closeable: false, /* Set true to make the alert closeable */
				display: 'inline', /* 'inline' (default) | 'sitewide' | 'section' | 'overlay' | 'notification' */
				location: false, /* 'false' (default) | bottomRight' | 'topRight' (only for use with contextual growls) */
				duration: -1, /* Milliseconds before the alert automatically closes */
				removable: false, /* Set true to remove alert from DOM when closed */
				onClose: false, /* function($alertElement){} */
				onOpen: false, /* function($alertElement){} */
				open: true, /* Set false to hide on page load */
				type: 'warning' /* 'warning' (default) | success' | 'info' */
			},
			langSets: {
				de: { closeBtn: 'Warnung schließen' },
				en: { closeBtn: 'Close alert' },
				es: { closeBtn: 'Cerrar alerta' },
				fr: { closeBtn: 'Fermer l’alerte' },
				it: { closeBtn: 'Chiudi avviso' },
				sv: { closeBtn: 'Stäng meddelande' }
			}
		}
	};
	$.fn.alert = function init(options, ...args) {
		const isMethodCall = typeof options === 'string'; /* If options is a string, we assume it refers to a method. */
		let	returnValue = this;
		this.each((i, el) => {
			const $el = $(el);
			if (isMethodCall) {
				const instance = $el.data('alertInstance');
				if (!instance) {
					ui.warn(`Cannot call methods on alert prior to initialization; attempted to call method "${options}".`);
					return false;
				}
				if (!$.isFunction(instance[options]) || options.indexOf('_') === 0) {
					ui.warn(`No such method "${options}" for alert.`);
					return false;
				}
				const methodValue = instance[options](...args);
				if (methodValue !== instance && methodValue !== undefined) {
					returnValue = methodValue && methodValue.jquery ? returnValue.pushStack(methodValue.get()) : methodValue;
					return false;
				}
			} else {
				alert.create($el, options);
			}
		});
		return returnValue;
	};
	$.alert = {
		getTest() {
			return Object.assign({}, alert);
		}
	};
	/* auto-instantiate if necessary */
	$(() => $('.alert:not([data-auto-instantiate="off"])').alert());
})(jQuery);

/****************************************
	Autocomplete
*****************************************/
($ => {
	if ($.autocomplete) {
		ui.warn('ERROR: You are including autocomplete.js multiple times');
		return;
	}
	const autocomplete = {
		create($element, options) {
			let instance = $element.data('autocompleteInstance');
			if (instance) {
				instance.destroy();
			}
			if (!options.key) {
				ui.warn('Autocomplete requires the "key" option.');
				return;
			}
			/* create and add #autocomplete element to the body if it does not exist */
			if (!$('#autocomplete').length) {
				$('body').append('<div id="autocomplete"></div>');
			}
			instance = Object.create(this.proto);
			instance.config = Object.assign({}, this.staticProperties.defaults, options);
			const config = instance.config;
			config.minLength = Math.max(1, config.minLength);
			instance.$element = $element;
			instance._run = ui.debounce(() => instance._retrieveResults(), 200);
			instance.iOSFix = window.navigator.userAgent.match(/iPhone|iPad|iPod/i);
			/* instance state data */
			instance.cachedData = {};
			instance.isOpen = false;
			instance.staticAjaxResponse = [];
			instance.screenTouches = {};
			instance.term = '';
			/* instance elements */
			instance.$autocomplete = $('#autocomplete');
			const id = $element.attr('id');
			instance.$container = $(`<div id="${id}ResultCon" class="autocompleteCon" />`);
			instance.$resultsList = $(`<ul id="${id}Results" class="autocompleteResults loading" role="listbox" />`);
			$element
				.addClass('autocompleteInput')
				.attr({
					'aria-autocomplete': 'list',
					'aria-controls': `${id}Results`,
					'aria-expanded': 'false',
					'aria-owns': `${id}Results`,
					autocomplete: 'off',
					role: 'combobox'
				});
			instance.$container.append(instance.$resultsList);
			instance.$autocomplete.append(instance.$container);
			/* add events */
			instance.$container
				.on('click.autocomplete', () => instance._handleResultsClick())
				.on('keydown.autocomplete', e => instance._handleResultsKeydown(e));
			$element
				.on('keydown.autocomplete', e => instance._handleInputKeydown(e))
				.on('focusin.autocomplete', () => instance._handleInputFocusin())
				.on('input.autocomplete', () => instance._handleInput())
				.on('focusout.autocomplete', () => instance._cancelRequest());
			/* Save instance to $element */
			$element.data('autocompleteInstance', instance);
			if (ui.isValidCallback(config.onCreate)) {
				config.onCreate.apply($element, []);
			}
			$element.trigger('initialized.autocomplete');
			return instance;
		},
		proto: {
			_ajaxRequest() {
				const config = this.config;
				const params = {};
				if (config.queryParameter) {
					params[config.queryParameter] = this.term;
				}
				let haveFullDataset = false;
				const self = this;
				const originalAjaxObject = {
					url: config.source,
					data: params,
					dataType: config.dataType,
					error(jqXHR, textStatus, errorThrown) {
						if (textStatus === 'abort') {
							return;
						}
						ui.warn(`Autocomplete Source ${config.source} cannot be reached`);
						if (ui.isValidCallback(config.onError)) {
							config.onError.apply(self.$element, [jqXHR, textStatus, errorThrown]);
						}
						self.close();
					},
					success(data) {
						let dirtyResults = [];
						if (ui.isValidCallback(config.jsonConversion)) {
							dirtyResults = config.jsonConversion.apply(self.$element, [data, config]);
						} else if (config.dataType === 'xml') {
							const parent = $(data).find(config.key).parent();
							parent.each((i, ele) => {
								const returnObj = {};
								$(ele)
									.children()
									.each((j, child) => {
										returnObj[child.nodeName] = $(child).text();
									});
								dirtyResults.push(returnObj);
							});
						} else {
							dirtyResults = data;
						}
						if (haveFullDataset) {
							self.staticAjaxResponse = dirtyResults;
						}
						const cleanResults = self._cacheResults(dirtyResults);
						if (!cleanResults.length && config.disableNoResultsMessage) {
							self.close();
						} else {
							self._open();
							self._populate(cleanResults);
						}
					}
				};
				this.$resultsList.addClass('loading');
				let overrides;
				if (ui.isValidCallback(config.ajaxOverride)) {
					overrides = config.ajaxOverride.apply(this.$element, [{
						url: originalAjaxObject.url,
						data: originalAjaxObject.data,
						dataType: originalAjaxObject.dataType
					}]);
				} else {
					overrides = config.ajaxOverride;
				}
				const updatedAjaxObject = Object.assign({}, originalAjaxObject, overrides);
				if ($.isEmptyObject(updatedAjaxObject.data)) {
					haveFullDataset = true;
				}
				this._cancelRequest();
				this.ajaxRequest = $.ajax(updatedAjaxObject);
			},
			_bindMouseEvents() {
				this.$container
					.on('mouseenter.autocomplete', '.autocompleteResult:not([aria-disabled]):not(.autocompleteNoResults)', e => {
						this.$focus = this._focusItem($(e.currentTarget));
						return true;
					})
					.on('mouseleave.autocomplete', '.autocompleteResult:not([aria-disabled]):not(.autocompleteNoResults)', (e, forceClearFocus) => {
						/* IE11 has an odd touch behavior that triggers an extra mouseleave event before selecting an item. In that extra event the 'relatedTarget' property is null. Once we drop support for IE11 touch, we can remove the condition from this clearFocus call. */
						/* Manually calling $result.trigger('mouseleave') results in an undefined `e.relatedTarget`.  We needed a way to force `clearFocus()` in our tests. This can be removed with the removal of `e.relatedTarget` */
						if (e.relatedTarget || forceClearFocus) {
							this._clearFocus();
						}
						return true;
					});
			},
			_buildResult(item, i, elementId) {
				const config = this.config;
				let itemData = {
					term: this.term,
					value: item[config.key],
					display: this._getHighlightedValue(this.term, item[config.key]),
					raw: item
				};
				if (ui.isValidCallback(config.customDisplay)) {
					itemData = config.customDisplay.apply(this.$element, [itemData]);
				}
				let classes = 'autocompleteResult';
				if (itemData.checked === true) {
					classes += ' autocompleteChecked iconAfter iconAfterCheck';
				}
				let attributes = 'tabindex="0"';
				if (itemData.disabled) {
					attributes = 'aria-disabled="true"';
				}
				const id = `${elementId}Autocomplete${i}`;
				const $result = $(`<li ${attributes} class="${classes}" role="option" aria-selected="false" id="${id}"><div class="textWrap">${itemData.display}</div></li>`);
				$result.data({
					raw: item,
					value: itemData.value
				});
				if (!itemData.disabled) {
					$result.on('click.autocomplete', e => this._handleResultClick(e));
				}
				return $result;
			},
			_cacheResults(dirtyResults) {
				this.fullDataset = dirtyResults;
				let cleanResults = [];
				if (this.config.queryParameter) {
					cleanResults = dirtyResults;
				} else {
					dirtyResults.forEach(item => {
						if (this._compare(item)) {
							cleanResults.push(item);
						}
					});
				}
				this.cachedData[this.term.toLowerCase()] = cleanResults;
				return cleanResults;
			},
			_cancelRequest() {
				if (this.ajaxRequest && this.ajaxRequest.state() === 'pending') {
					this.ajaxRequest.abort();
				}
			},
			_clearFocus() {
				if (this.$focus) {
					this.$focus
						.removeAttr('aria-selected')
						.removeClass('autocompleteSelected');
					this.$focus = false;
				}
			},
			close() {
				if (this.isOpen) {
					const config = this.config;
					if (ui.isValidCallback(config.onClose)) {
						config.onClose.apply(this.$element, [this.$container, this.$before, this.$after]);
					}
					if (this.$before) {
						this.$before.remove();
						this.$before = false;
					}
					if (this.$after) {
						this.$after.remove();
						this.$after = false;
					}
					/* move $autocomplete back to body if it is in a modal */
					if (this.inModal || this.inCallout) {
						this.$autocomplete.appendTo('body');
					}
					const id = this.$element.attr('id');
					$(window)
						.add('html')
						.add('body')
						.off(`.autocomplete.${id}`);
					this._clearFocus();
					this.$container
						.off('mouseenter.autocomplete mouseleave.autocomplete')
						.removeClass('autocompleteVisible autocompleteInModal autocompleteInCallout');
					this.$resultsList.empty();
					this.$autocomplete.css('max-height', '');
					this.$element
						.attr('aria-expanded', 'false')
						.removeClass('autocompleteAttached autocompleteInputInCallout');
					this.isOpen = false;
					this.$element.trigger('closed.autocomplete');
				}
			},
			_compare(item) {
				const config = this.config;
				const regEx = new RegExp(ui.escapeRegExp(this.term), 'gi');
				const termSearch = item[config.key] ? item[config.key].search(regEx) : -1;
				return (config.matchBeginning && termSearch === 0) || (!config.matchBeginning && termSearch !== -1);
			},
			destroy() {
				this.close();
				this.$element.add(this.$container).add(this.$resultsList).off('.autocomplete');
				this.$element
					.removeClass('autocompleteInput')
					.removeAttr('aria-autocomplete aria-controls aria-expanded aria-owns autocomplete role')
					.removeData('autocompleteInstance raw');
				this.$container.remove();
			},
			flush() {
				this.cachedData = {};
			},
			_focusItem($item) {
				this._clearFocus();
				$item
					.addClass('autocompleteSelected')
					.focus();
				if ($item.closest(this.$resultsList).length) {
					$item.attr('aria-selected', 'true');
				}
				if (ui.isValidCallback(this.config.onFocus)) {
					this.config.onFocus.apply(this.$element, [$item]);
				}
				this.$element.trigger('itemFocused.autocomplete');
				return $item;
			},
			_getHighlightedValue(searchTerm, highlightedValue) {
				const config = this.config;
				let value = highlightedValue;
				if (config.highlight) {
					const regex = new RegExp(ui.escapeRegExp(searchTerm), 'gi');
					if (config.highlight === true) {
						value = value.replace(regex, matched => `<span class="autocompleteHighlighted">${matched}</span>`);
					} else {
						const openPattern = new RegExp(ui.escapeRegExp(config.highlight.open), 'g');
						const closePattern = new RegExp(ui.escapeRegExp(config.highlight.close), 'g');
						value = value
							.replace(openPattern, '<span class="autocompleteHighlighted">')
							.replace(closePattern, '</span>');
					}
				}
				return value;
			},
			_handleFocusOrMouseDownOnOtherElement(e) {
				const $target = $(e.target);
				const targetInContainer = !!$target.closest(this.$container).length;
				const targetIsCurrentCallout = this.inCallout && $target.is('.calloutContent');
				const targetIsCurrentElement = $target.is(this.$element);
				/*	This check is to see if a new element is focused outside of the current autocomplete (such as another input).
					The variable targetIsCurrentCallout is needed because Safari and FF incorrectly place focus onto the
					.calloutContent element because it has tabindex="-1". Since .calloutContent is not within the autocomplete
					container, it would cause the autocomplete to close before event would occur on the children elements. */
				if (!targetInContainer && !targetIsCurrentCallout && !targetIsCurrentElement) {
					this.close();
				}
				return true;
			},
			_handleHtmlTouchendOrClick(e) {
				const $target = $(e.target);
				const isLabel = $target.attr('for');
				const $input = isLabel ? $(`#${isLabel}`) : false;
				const maxTapDistance = 5;
				const isTapEvent = e.originalEvent.changedTouches ? Math.max(Math.abs(this.screenTouches.x - e.originalEvent.changedTouches[0].pageX), Math.abs(this.screenTouches.y - e.originalEvent.changedTouches[0].pageY)) < maxTapDistance : false;
				const targetInAutocomplete = !!$target.closest(this.$autocomplete).length;
				const targetInElement = !!$target.closest(this.$element).length;
				/* prevent close when clicking inside autocomplete not on a result */
				if (!targetInElement && !targetInAutocomplete && (($input && !$input.hasClass('autocompleteAttached')) || (!$input && !$target.hasClass('autocompleteAttached')))) {
					if (e.type === 'click' || isTapEvent) {
						this.close();
					}
				}
				return true;
			},
			_handleHtmlTouchstart(e) {
				this.screenTouches.x = e.originalEvent.touches ? e.originalEvent.touches[0].pageX : e.pageX;
				this.screenTouches.y = e.originalEvent.touches ? e.originalEvent.touches[0].pageY : e.pageY;
				return true;
			},
			_handleInput() {
				if (this.$element.data('raw')) {
					this.$element.removeData('raw');
					if (ui.isValidCallback(this.config.onChange)) {
						this.config.onChange.apply(this.$element, []); /* Available strictly so apps can detect a value change regardless of raw data being present. */
					}
				}
				this._run();
			},
			_handleInputFocusin() {
				if (this.isOpen) {
					this._clearFocus();
				} else {
					this._retrieveResults();
				}
				return true;
			},
			_handleInputKeydown(e) {
				const key = e.which;
				if (key === ui.keyCodes.tab || key === ui.keyCodes.esc) {
					this.close();
				} else if (key === ui.keyCodes.up || key === ui.keyCodes.down) {
					this._navigateListItems(key === ui.keyCodes.up);
					return false;
				}
				return true;
			},
			_handleResultClick(e) {
				const $result = $(e.currentTarget);
				if ($result.attr('aria-disabled')) {
					return false;
				}
				this._selectItem($result);
				return true;
			},
			_handleResultsClick() {
				if (this.inModal && $.modal) {
					$.modal.preventClosing();
				}
				return true;
			},
			_handleResultsKeydown(e) {
				const key = e.which;
				const $result = $(e.target);
				const isResult = !!$result.closest(this.$resultsList).length;
				const tabOnResult = isResult && key === ui.keyCodes.tab;
				const enterOnResult = isResult && key === ui.keyCodes.enter;
				const shouldSelectResult = tabOnResult || enterOnResult;
				const shouldStopEvent = enterOnResult || key === ui.keyCodes.esc || key === ui.keyCodes.up || key === ui.keyCodes.down || key === ui.keyCodes.leftBracket || key === ui.keyCodes.rightBracket;
				const shouldClose = key === ui.keyCodes.esc || (key === ui.keyCodes.tab && !isResult);
				const shouldFocusOnElement = shouldClose || (!shouldStopEvent && key !== ui.keyCodes.enter && !$result.is('input, textarea'));
				const shouldNavigateUp = key === ui.keyCodes.up || key === ui.keyCodes.leftBracket;
				const shouldNavigateDown = key === ui.keyCodes.down || key === ui.keyCodes.rightBracket;
				if (shouldSelectResult) {
					this._selectItem($result);
				} else if (shouldFocusOnElement) {
					this.$element.focus();
				} else if (shouldNavigateUp) {
					this._navigateListItems(true);
				} else if (shouldNavigateDown) {
					this._navigateListItems();
				} else if (shouldClose) {
					this.close();
				}
				return !shouldStopEvent;
			},
			_navigateListItems(goUp) {
				const $tabbable = ui.getTabbableElements(this.$container);
				if (!$tabbable.length) {
					/* There are no results and no interactive content */
					return false;
				}
				let $targetElement = false;
				if (!this.$focus) {
					$targetElement = goUp ? $tabbable.last() : $tabbable.first();
				} else {
					const $endElement = goUp ? $tabbable.first() : $tabbable.last();
					if (!this.$focus.is($endElement)) {
						const currentIndex = $tabbable.index(this.$focus);
						const targetIndex = goUp ? currentIndex - 1 : currentIndex + 1;
						$targetElement = $tabbable.eq(targetIndex);
					}
				}
				if ($targetElement) {
					this.$focus = this._focusItem($targetElement);
				} else {
					this.$element.focus();
				}
			},
			_open() {
				this.inModal = this.$element.closest('.modal, #modal').length > 0;
				this.inCallout = this.$element.closest('#callout').length > 0;
				this.$resultsList
					.off('click.autocomplete.noResults')
					.removeClass('loading autocompleteHasChecks')
					.empty();
				if (!this.inCallout) {
					this.position();
				}
				if (!this.isOpen) {
					const config = this.config;
					if (ui.isValidCallback(config.onOpen)) {
						this.$before = $('<div class="autocompleteBefore" />');
						this.$after = $('<div class="autocompleteAfter" />');
						config.onOpen.apply(this.$element, [this.$container, this.$before, this.$after]);
						if (!this.$before.is(':empty')) {
							this.$resultsList.before(this.$before);
						}
						if (!this.$after.is(':empty')) {
							this.$resultsList.after(this.$after);
						}
					}
					this.$element
						.addClass('autocompleteAttached')
						.attr('aria-expanded', 'true');
					this.$container.addClass('autocompleteVisible');
					const id = this.$element.attr('id');
					if (this.inCallout) {
						this.$element.addClass('autocompleteInputInCallout');
						this.$container.addClass('autocompleteInCallout');
						if (this.$element.parent('.labelIconOnly').length) {
							this.$autocomplete.insertAfter(this.$element.parent());
						} else {
							this.$autocomplete.insertAfter(this.$element);
						}
					} else {
						$(window).on(`resize.autocomplete.${id}`, ui.debounce(() => {
							this.position();
							return true;
						}, 100));
						if (this.inModal) {
							this.$container.addClass('autocompleteInModal');
							this.$autocomplete.appendTo('.modalFlex.open');
						}
					}
					$('html')
						.on(`touchstart.autocomplete.${id}`, e => this._handleHtmlTouchstart(e))
						.on(`touchend.autocomplete.${id} click.autocomplete.${id}`, e => this._handleHtmlTouchendOrClick(e));
					$('body').on(`focusin.autocomplete.${id} mousedown.autocomplete.${id}`, e => this._handleFocusOrMouseDownOnOtherElement(e));
					this.isOpen = true;
					this._bindMouseEvents();
					this.$element.trigger('opened.autocomplete');
				}
			},
			_populate(results) {
				if (ui.isValidCallback(this.config.onResponse)) {
					this.config.onResponse.apply(this.$element, [results]);
				}
				this.populatedResults = results;
				if (results.length) {
					const elementId = this.$element.attr('id');
					const maxResults = results.slice(0, this.config.maxResults);
					maxResults.forEach((item, i) => {
						const $result = this._buildResult(item, i, elementId);
						this.$resultsList.append($result);
					});
					if (this.$resultsList.find('.autocompleteChecked').length) {
						this.$resultsList.addClass('autocompleteHasChecks');
					}
				} else {
					this.$resultsList
						.html(`<li class="autocompleteResult autocompleteNoResults" role="alert" label="Search Results">${ui.localize(autocomplete.staticProperties.langSets, 'noMatches')}</li>`)
						.on('click.autocomplete.noResults', () => false);
					this.$element.removeData('raw');
				}
				this.$element.trigger('populated.autocomplete');
			},
			position() {
				this.$container.css('max-height', '');
				const offset = this.$element.offset();
				let top = Number(offset.top + this.$element.outerHeight(false));
				if (this.inModal) {
					top += $('#modalFixed').scrollTop();
					if (!this.iOSFix) {
						top -= $(window).scrollTop();
					}
				}
				this.$container.css({
					left: offset.left,
					top,
					width: this.$element.outerWidth(false)
				});
				this.$element.trigger('positioned.autocomplete');
			},
			_retrieveResults() {
				const prevTerm = this.term;
				this.term = this.$element.val();
				const unchangedAndOpen = this.term === prevTerm && this.isOpen;
				/* Don't run if the input is no longer focused (this is a delayed debounce) */
				const isFocused = $(document.activeElement).attr('id') === this.$element.attr('id');
				if (unchangedAndOpen || !isFocused) {
					return;
				}
				const config = this.config;
				const enoughCharacters = this.term.length >= config.minLength;
				if (!enoughCharacters) {
					if (config.alwaysOpenOnFocus && !this.isOpen) {
						this._open();
					} else if (!config.alwaysOpenOnFocus && this.isOpen) {
						this.close();
					} else {
						this.$resultsList.empty();
					}
				} else {
					if (ui.isValidCallback(config.onSearch)) {
						config.onSearch.apply(this.$element, [this.term]);
					}
					let results;
					let convertedData;
					if (Array.isArray(this.cachedData[this.term.toLowerCase()])) {
						results = this.cachedData[this.term.toLowerCase()];
					} else if ($.isFunction(config.source)) {
						convertedData = ui.isValidCallback(config.jsonConversion) ? config.jsonConversion.apply(this.$element, [config.source.apply(this.$element, [this.term])]) : config.source.apply(this.$element, [this.term]);
						results = convertedData;
					} else if (typeof config.source === 'object') {
						convertedData = ui.isValidCallback(config.jsonConversion) ? config.jsonConversion.apply(this.$element, [config.source, config]) : config.source;
						results = this._cacheResults(convertedData);
					} else if (typeof config.source === 'string') {
						if (this.staticAjaxResponse && this.staticAjaxResponse.length) {
							results = this._cacheResults(this.staticAjaxResponse);
						} else {
							this._ajaxRequest();
						}
					}
					if (results) {
						if (!results.length && config.disableNoResultsMessage) {
							this.close();
						} else {
							this._open();
							this._populate(results);
						}
					}
				}
			},
			run() {
				ui.warn('DEPRECATION NOTICE: Please remove the "autocomplete(\'run\')" method call.');
				this._run();
			},
			_selectItem($item) {
				if ($item && this.$focus && $item[0] === this.$focus[0]) {
					const config = this.config;
					const raw = $item.data('raw');
					this.$element
						.data('raw', raw)
						.val($item.data('value'));
					if (ui.isValidCallback(config.onResultSelect)) {
						ui.warn('DEPRECATION NOTICE: Please replace the autocomplete method "onResultSelect" with "onItemSelect"');
						config.onResultSelect.apply(this.$element, [$item]);
					}
					if (ui.isValidCallback(config.onItemSelect)) {
						config.onItemSelect.apply(this.$element, [raw]);
					}
				} else {
					this.$element.removeData('raw');
				}
				this.$element.focus();
				this.close();
			}
		},
		staticProperties: {
			defaults: {
				ajaxOverride: {}, /* This will be merged into the object that gets passed into the $.ajax method */
				alwaysOpenOnFocus: false, /* Boolean: Opens the autocomplete container whether or not the minLength is met */
				customDisplay: false, /* function (data) { return { value: value, display: value, disabled: boolean, checked: boolean }; },  MUST RETURN OBJECT; Custom function for displaying the returned data. 'disabled' and 'checked' properties are not required. */
				dataType: 'json', /* defaults to json; 'xml' if xml data */
				disableNoResultsMessage: false, /* autocomplete will be closed rather than show a no results message */
				highlight: true, /* default: true, other options: { open: 'yourOpenChars', close: 'yourCloseChars' }, or false */
				jsonConversion: false, /* Custom function for converting data to properly formatted json: function (data, options) { return [{key:value, key2:value},{key:value, key2:value}]; } */
				key: '', /* REQUIRED; Key for key:value pair you are searching for */
				matchBeginning: false, /* Allows you to return only matches based on the first characters */
				maxResults: 20, /* Max results shown */
				minLength: 3, /* Number of characters to use for search; minimum of 1 */
				onChange: false, /* Callback function run when the autocomplete value changes. function () { } (this === $input) */
				onClose: false, /* Callback function run when autocomplete is closed: function ($resultContainer, $before, $after) { } (this === $input) */
				onCreate: false, /* Callback function run when autocomplete is created. function () { } (this === $input) */
				onError: false, /* Callback function run if an ajax request fails. function (jqXHR, textStatus, errorThrown) { } (this === $input) */
				onFocus: false, /* Callback function run when a result item is focused. function ($item) { } (this === $input) */
				onItemSelect: false, /* Callback function run when an item is selected from dropdown: function (itemData) { } (this === $input) */
				onOpen: false, /* Callback function run when autocomplete is opened: function ($resultContainer, $before, $after) { } (this === $input) */
				onResponse: false, /* Callback function run when autocomplete receives a response. function (results) { } (this === $input) */
				onSearch: false, /* Callback function run when autocomplete is ready to perform a search. function (term) { } (this === $input) */
				queryParameter: false, /* REQUIRED FOR SERVICE CALL; false, if none needed; otherwise string */
				source: '' /* REQUIRED; The url of the data or an array of objects representing searchable data. */
			},
			langSets: {
				de: {
					noMatches: 'Keine Übereinstimmungen gefunden'
				},
				en: {
					noMatches: 'No matches found'
				},
				es: {
					noMatches: 'No se encontraron concordancias'
				},
				fr: {
					noMatches: 'Aucune correspondance trouvée'
				},
				it: {
					noMatches: 'Nessuna corrispondenza trovata'
				},
				sv: {
					noMatches: 'Inga matchningar hittades'
				}
			}
		}
	};
	$.fn.autocomplete = function init(options, ...args) {
		const isMethodCall = typeof options === 'string'; /* If options is a string, we assume it refers to a method. */
		let returnValue = this;
		this.each((i, el) => {
			const $el = $(el);
			if (isMethodCall) {
				const instance = $el.data('autocompleteInstance');
				if (!instance) {
					ui.warn(`Cannot call methods on autocomplete prior to initialization; attempted to call method "${options}".`);
					return false;
				}
				if (!$.isFunction(instance[options]) || options.indexOf('_') === 0) {
					ui.warn(`No such method "${options}" for autocomplete.`);
					return false;
				}
				const methodValue = instance[options](...args);
				if (methodValue !== instance && methodValue !== undefined) {
					returnValue = methodValue && methodValue.jquery ? returnValue.pushStack(methodValue.get()) : methodValue;
					return false;
				}
			} else {
				autocomplete.create($el, options);
			}
		});
		return returnValue;
	};
	$.autocomplete = {
		close() {
			const $autocompleteInput = $('.autocompleteAttached');
			if ($autocompleteInput.length) {
				$autocompleteInput.autocomplete('close');
			}
		},
		getTest() {
			return Object.assign({}, autocomplete);
		},
		position() {
			const $autocompleteInput = $('.autocompleteAttached');
			if ($autocompleteInput.length) {
				$autocompleteInput.autocomplete('position');
			}
		}
	};
})(jQuery);

/****************************************
	Callout
*****************************************/
($ => {
	if ($.callout) {
		ui.warn('ERROR: You are including callout.js multiple times');
		return;
	}
	const callout = {
		create($element, options) {
			let instance = $element.data('calloutInstance');
			if (instance) {
				instance.destroy();
			}
			instance = Object.create(this.proto);
			instance.$element = $element;
			instance.config = instance._generateConfig(options);
			if (!instance.config.content) {
				ui.warn('WARNING: You must provide a \'content\' property to create a callout.');
				return;
			}
			instance.uniqueId = $element.attr('id') || `callout-${ui.getRandomNumber()}`;
			instance.isOpen = false;
			instance.modalOffset = 0;
			instance.$scrollable = $element.closest('.calloutScrollPane');
			if (instance.config.type !== 'field') {
				$element.attr('aria-expanded', false);
			}
			instance._generateMarkup();
			instance.$content.html(instance._parseContent());
			instance._initClasses();
			instance._addCreateEvents();
			$element.data('calloutInstance', instance);
			return instance;
		},
		proto: {
			_addCreateEvents() {
				const config = this.config;
				if (config.type === 'field') {
					const extraEvent = this.$element.is('select') ? ' mouseenter.callout.create' : '';
					this.$element
						.on(`focus.callout.create${extraEvent}`, () => {
							this.open();
						})
						.on('click.callout.create', e => {
							e.stopPropagation();
						});
				} else if (config.type === 'hover' || config.type === 'cursor') {
					this.$element
						/* We need this touchend because mouseenter isn't enough on mobile. */
						.on('mouseenter.callout.create touchend.callout.create', ui.throttle(() => {
							this.hovering = true;
							this.open();
						}, 100, true))
						.on('mouseleave.callout.create', e => {
							/* IE11 and Edge on Surface trigger a mouseleave event during a tap (touch). If e.relatedTarget and e.toElement are null we assume it was this scenario and don't close the callout. */
							if (e.relatedTarget || e.toElement) {
								this.hovering = false;
								this._startCloseTimer();
							}
						});
				} else {
					this.$element.on('click.callout.create', e => {
						if (this.isOpen) {
							/* Clicking the trigger of a guidance callout should not close the callout. */
							if (config.type !== 'guidance') {
								this.close();
							}
						} else {
							if (this.$element.is('a')) {
								e.preventDefault();
							}
							setTimeout(() => {
								/* Click event has finished bubbling and won't interfere with any of the onOpen callbacks. */
								this.open();
							});
						}
					});
				}
			},
			_addOpenEvents() {
				const config = this.config;
				if (config.type === 'field') {
					this.$element.on('blur.callout.open', () => {
						this.close();
					});
				}
				/* Add html click event */
				if (config.type !== 'custom' && config.type !== 'guidance') {
					const touches = {};
					$('html')
						.on(`touchstart.callout.${this.uniqueId}`, e => {
							touches.x = e.originalEvent.touches ? e.originalEvent.touches[0].pageX : e.pageX;
							touches.y = e.originalEvent.touches ? e.originalEvent.touches[0].pageY : e.pageY;
						})
						.on(`touchend.callout.${this.uniqueId} click.callout.${this.uniqueId}`, ui.throttle(e => {
							this._handleHtmlClick(e, touches);
						}, 100, true));
				}
				/* Add callout hover events */
				if (config.type === 'hover' || config.type === 'cursor') {
					this.$wrap
						.on('mouseenter.callout', () => {
							this.hovering = true;
							this._stopCloseTimer();
						})
						.on('mouseleave.callout', () => {
							this.hovering = false;
							this._startCloseTimer();
						});
				}
				this.$content.on('keydown.callout', e => {
					if (e.which === ui.keyCodes.tab) {
						this._handleTabPress(e);
					}
				});
				$(window).on(`resize.callout.${this.uniqueId}`, ui.throttle(() => {
					if (this.isOpen) {
						if (this.$element.is(':visible')) {
							this.position();
						} else {
							this.close();
						}
					}
				}, 50));
				/* Handle scrollable containers */
				if (this.$scrollable.length) {
					/* Every 16 ms === 60 fps. */
					this.$scrollable.on(`scroll.callout.${this.uniqueId}`, ui.throttle(() => {
						if (this._inView()) {
							this.position();
						} else {
							this.close();
						}
					}, 16));
				}
			},
			_applyPosition(position, coords) {
				const styles = this._calculatePosition(position, coords);
				this.$wrap.css(this._convertToTransform(styles));
				if (this.config.type !== 'field') {
					const alignStyles = this._calculateAlignment(position);
					if (alignStyles.wrap) {
						this.$wrap.css(this._convertToTransform(alignStyles.wrap));
					}
					if (!this.config.hidePointer && alignStyles.pointer) {
						this.$pointer.css(this._convertToTransform(alignStyles.pointer));
					}
				}
				/* The callout has now been positioned and should now be made visible. */
				this.$wrap.removeClass('unpositioned');
			},
			_calculateAlignment(position) {
				const wrap = this._getLocation(this.$wrap);
				const element = this._getLocation();
				const isVertical = position === 'left' || position === 'right';
				/* If wrap is smaller than element, no alignment will be necessary. */
				const alreadyAligned = isVertical ? wrap.height < element.height : wrap.width < element.width;
				if (alreadyAligned) {
					return {};
				}
				const win = this._getLocation($(window));
				const wrapAlign = { left: wrap.left, top: wrap.top };
				const pointerAlign = { left: 0, top: 0 };
				/* Minimum space required for pointer to look nice. Half of pointer size (16px) plus 5px. */
				const pointerOffset = 21;
				/* Minimum space require to align wrap edge with element edge. */
				const elementOffset = isVertical ? element.height / 2 : element.width / 2;
				/* Cursor callouts should only be limited by the pointerOffset. */
				const minimumOffset = this.config.type === 'cursor' ? pointerOffset : Math.max(pointerOffset, elementOffset);
				/* Distance from the wrap's center to the wrap's edge. */
				const wrapEdge = isVertical ? wrap.height / 2 : wrap.width / 2;
				/* Largest aligment adjustment we can make and still look ok. */
				const maxAdjustment = wrapEdge - minimumOffset;
				/* Direction determines whether we add to or subtract from current offsets. */
				let direction = 1;
				let beyondViewport = 0;
				if (isVertical) {
					const viewportTop = win.scrollTop + win.padding;
					const viewportBottom = win.scrollTop + win.height - win.padding;
					if (wrap.top < viewportTop) {
						beyondViewport = viewportTop - wrap.top;
					} else if (wrap.bottom > viewportBottom) {
						direction = -1;
						beyondViewport = wrap.bottom - viewportBottom;
					}
					/* Don't go past the maxAdjustment limit. */
					const adjustment = direction * Math.min(beyondViewport, maxAdjustment);
					/* Add the adjustment to the current wrap.top. */
					wrapAlign.top = wrap.top + adjustment;
					/* Add the adjustment to pointer.top. */
					pointerAlign.top = -adjustment;
				} else {
					const viewportLeft = win.scrollLeft + win.padding;
					const viewportRight = win.scrollLeft + win.width - win.padding;
					if (wrap.left < viewportLeft) {
						beyondViewport = viewportLeft - wrap.left;
					} else if (wrap.right > viewportRight) {
						direction = -1;
						beyondViewport = wrap.right - viewportRight;
					}
					/* Don't go past the maxAdjustment limit. */
					const adjustment = direction * Math.min(beyondViewport, maxAdjustment);
					/* Add the adjustment to the current wrap.left. */
					wrapAlign.left = wrap.left + adjustment;
					/* Add the adjustment to the current pointer.left. */
					pointerAlign.left = -adjustment;
				}
				return {
					pointer: pointerAlign,
					wrap: wrapAlign
				};
			},
			_calculatePosition(position, coords) {
				const self = this;
				const wrap = this._getLocation(this.$wrap);
				const element = this._getLocation();
				const positionStyles = {
					bottom() {
						return {
							/* Align wrap.center to element.center */
							left: element.center - (wrap.width / 2),
							/* Align wrap.top to element.bottom */
							top: element.bottom
						};
					},
					cursor() {
						/* How far the callout should be placed below the cursor pointer. */
						const cursorOffset = 20;
						return {
							/* Align wrap.center to cursor */
							left: coords.pageX - (wrap.width / 2),
							/* Align wrap.top to cursor */
							top: coords.pageY + cursorOffset - self.modalOffset
							/* This is the only modal-dependent calculation outside of _getLocation. */
						};
					},
					field() {
						const win = self._getLocation($(window));
						/* Minimum width for a multi-line callout (preserves readability) */
						const minimumMaxWidth = 180;
						const desiredWidth = Math.max(minimumMaxWidth, element.width);
						const viewportRight = win.width - win.padding;
						const spaceAvailable = viewportRight - element.left;
						let correction = 0;
						if (spaceAvailable < desiredWidth) {
							correction = desiredWidth - spaceAvailable;
						}
						return {
							/* Align wrap.left to element.left (plus correction) */
							left: element.left - correction,
							'max-width': desiredWidth,
							/* Align wrap.top to element.bottom */
							top: element.bottom
						};
					},
					left() {
						return {
							/* Align wrap.right to element.left */
							left: element.left - wrap.width,
							/* Align wrap.middle to element.middle */
							top: element.middle - (wrap.height / 2)
						};
					},
					right() {
						return {
							/* Align wrap.left to element.right */
							left: element.right,
							/* Align wrap.middle to element.middle */
							top: element.middle - (wrap.height / 2)
						};
					},
					top() {
						return {
							/* Align wrap.center to element.center */
							left: element.center - (wrap.width / 2),
							/* Align wrap.bottom to element.top */
							top: element.top - wrap.height
						};
					}
				};
				let pos = position;
				if (this.config.type === 'field') {
					pos = 'field';
				} else if (this.config.type === 'cursor') {
					pos = 'cursor';
				}
				return positionStyles[pos]();
			},
			close() {
				if (!this.isOpen) {
					return;
				}
				const config = this.config;
				if (ui.isValidCallback(config.onClose)) {
					config.onClose.apply(this.$element, [this.$element]);
				}
				this.$wrap.removeClass('open willTransform');
				this.$pointer.removeClass('willTransform');
				this.$element.removeClass('active');
				if (config.type !== 'field') {
					this.$element.attr('aria-expanded', false);
				}
				this.hovering = false;
				if (this._isFocusWithinCallout()) {
					this.$element.focus();
				}
				this._removeOpenEvents();
				if (this.inModal) {
					/* Move #callout back to body. */
					this.$callout.appendTo('body');
				}
				this.isOpen = false;
				this.$element.trigger('closed.callout');
			},
			_convertToTransform(styles) {
				const x = styles.left || 0;
				const y = styles.top || 0;
				const translate = `translate(${Math.round(x)}px, ${Math.round(y)}px)`;
				const transformed = {
					'-webkit-transform': translate,
					transform: translate
				};
				for (const prop in styles) {
					if (styles.hasOwnProperty(prop) && prop !== 'left' && prop !== 'top') {
						transformed[prop] = styles[prop];
					}
				}
				return transformed;
			},
			_createPlaceholder($original) {
				$original.before(`<span class="noDisplay" id="calloutPlaceholder-${this.uniqueId}"></span>`);
			},
			destroy() {
				this.close();
				this._removeCreateEvents();
				this._restorePlaceholder();
				this.$wrap.remove();
				this.$element
					.removeClass('calloutTrigger guidanceTrigger')
					.removeAttr('aria-expanded')
					.removeData('calloutInstance');
			},
			_generateConfig(options) {
				const classConfig = this._getConfigFromClasses();
				const dataConfig = this._getConfigFromDataAttributes();
				const config = Object.assign({}, callout.staticProperties.defaults, classConfig, dataConfig, options);
				/* Config overrides for special cases. */
				const isField = this.$element.is('input, select, textarea');
				if (isField || config.type === 'field') {
					config.hidePointer = true;
					config.position = 'bottom';
					config.style = options && options.style ? options.style : 'dark';
					config.type = 'field';
				} else if (config.type === 'cursor') {
					config.position = 'bottom';
				}
				return config;
			},
			_generateMarkup() {
				if (!$('#callout').length) {
					$('body').append('<div id="callout"></div>');
				}
				this.$callout = $('#callout');
				/* Create containers */
				this.$wrap = $(`<div class="callout" id="calloutWrap-${this.uniqueId}" />`);
				this.$content = $('<div class="calloutContent" tabindex="-1" />').appendTo(this.$wrap);
				this.$pointer = $('<div class="calloutPointer"><div class="calloutPointerShadow"></div></div>').appendTo(this.$wrap);
				this.$callout.append(this.$wrap);
			},
			_getBestPosition(position) {
				const config = this.config;
				const specialCase = config.type === 'field' || config.type === 'cursor';
				let bestPosition = position;
				if (!specialCase && !this._hasSpace(position)) {
					bestPosition = 'bottom';
				}
				return bestPosition;
			},
			_getConfigFromClasses() {
				const warnAboutConfig = (bad, good) => {
					ui.warn(`WARNING: Configuration via the '${bad}' class has been deprecated. Please use the '${good}' attribute for configuration.`);
				};
				const config = {};
				if (this.$element.hasClass('calloutHidePointer')) {
					warnAboutConfig('calloutHidePointer', 'data-hide-pointer');
					config.hidePointer = true;
				}
				if (this.$element.hasClass('calloutPositionTop')) {
					warnAboutConfig('calloutPositionTop', 'data-position');
					config.position = 'top';
				} else if (this.$element.hasClass('calloutPositionRight')) {
					warnAboutConfig('calloutPositionRight', 'data-position');
					config.position = 'right';
				} else if (this.$element.hasClass('calloutPositionLeft')) {
					warnAboutConfig('calloutPositionLeft', 'data-position');
					config.position = 'left';
				}
				if (this.$element.hasClass('calloutStyleDark')) {
					warnAboutConfig('calloutStyleDark', 'data-style');
					config.style = 'dark';
				} else if (this.$element.hasClass('calloutStyleAlt')) {
					warnAboutConfig('calloutStyleAlt', 'data-style');
					config.style = 'alt';
				}
				if (this.$element.hasClass('calloutTypeHover')) {
					warnAboutConfig('calloutTypeHover', 'data-type');
					config.type = 'hover';
				} else if (this.$element.hasClass('calloutTypeCursor')) {
					warnAboutConfig('calloutTypeCursor', 'data-type');
					config.type = 'cursor';
				} else if (this.$element.hasClass('calloutTypeCustom')) {
					warnAboutConfig('calloutTypeCustom', 'data-type');
					config.type = 'custom';
				}
				return config;
			},
			_getConfigFromDataAttributes() {
				const config = {};
				const data = this.$element.data();
				Object.keys(callout.staticProperties.defaults).forEach(key => {
					if (data.hasOwnProperty(key)) {
						config[key] = data[key];
					}
				});
				if (!config.content) {
					if (data.callout) {
						config.content = data.callout;
					} else if (this.$element.attr('title')) {
						config.content = this.$element.attr('title');
					}
				}
				return config;
			},
			_getLocation($element = this.$element) {
				/* All vertical offsets and positions must be adjusted if they are inside a modal. This is because our modal has fixed/absolute position. */
				const modalOffset = this.modalOffset;
				const isWindow = $element[0] === window;
				const details = {
					/* We don't want to include scrollbar(s) for window dimensions. */
					height: isWindow ? $element.height() : $element.outerHeight(false),
					width: isWindow ? $element.width() : $element.outerWidth(false),
					scrollLeft: $element.scrollLeft(),
					scrollTop: $element.scrollTop() - modalOffset
				};
				if (isWindow) {
					details.padding = callout.staticProperties.windowPadding;
				} else {
					const offset = $element.offset();
					details.bottom = offset.top + details.height - modalOffset;
					details.center = offset.left + (details.width / 2);
					details.left = offset.left;
					details.middle = offset.top + (details.height / 2) - modalOffset;
					details.right = offset.left + details.width;
					details.top = offset.top - modalOffset;
				}
				return details;
			},
			_getModalOffset() {
				let modalOffset = 0;
				if (this.inModal) {
					const $modal = $('#modalFixed');
					modalOffset = $modal.offset().top - $modal.scrollTop();
				}
				return modalOffset;
			},
			_getPositionClass(position = this.config.position) {
				const upper = ui.ucfirst(position);
				return `calloutPosition${upper}`;
			},
			_handleAutoFocus() {
				const config = this.config;
				if (config.disableAutofocusOnOpen !== true) {
					if (config.type === 'hover' || !ui.getTabbableElements(this.$content).length) {
						this.$content.attr('role', 'alert');
					} else if (!this._isFocusWithinCallout() && config.type !== 'field') {
						const $getScroll = this.inModal ? $('#modalFixed') : $(window);
						const oldScrollTop = $getScroll.scrollTop();
						this.$content.focus();
						const newScrollTop = $getScroll.scrollTop();
						if (newScrollTop !== oldScrollTop) {
							const $setScroll = this.inModal ? $getScroll : $('body, html');
							$setScroll.scrollTop(oldScrollTop);
						}
					}
				}
			},
			_handleHtmlClick(e, touches) {
				const $target = $(e.target);
				const inAutocomplete = $target.closest('.autocompleteResult').length > 0;
				const withinCallout = $target.closest('#callout').length > 0;
				const inTrigger = $target.closest(this.$element).length > 0;
				if (!withinCallout && !inAutocomplete && !inTrigger) {
					if (e.type === 'click') {
						this.close();
					} else {
						const pageX = e.originalEvent.changedTouches ? e.originalEvent.changedTouches[0].pageX : e.pageX;
						const pageY = e.originalEvent.changedTouches ? e.originalEvent.changedTouches[0].pageY : e.pageY;
						const deltaX = Math.abs(touches.x - pageX);
						const deltaY = Math.abs(touches.y - pageY);
						const isTap = this.config.type !== 'field' && Math.max(deltaX, deltaY) < 5;
						if (isTap) {
							this.close();
						}
					}
				}
			},
			_handleTabPress(e) {
				const focus = document.activeElement;
				const focusName = focus.getAttribute('name');
				const isRadio = focus.getAttribute('type') === 'radio';
				const contentIsFocus = focus === this.$content[0];
				const $tabbable = ui.getTabbableElements(this.$content);
				const $first = $tabbable.first();
				const $last = $tabbable.last();
				const firstIsFocus = focus === $first[0] || (isRadio && focusName === $first.attr('name'));
				const lastIsFocus = focus === $last[0] || (isRadio && focusName === $last.attr('name'));
				const prevFromFirst = e.shiftKey && (contentIsFocus || firstIsFocus);
				const nextFromLast = !e.shiftKey && lastIsFocus;
				if (prevFromFirst || nextFromLast) {
					this.$element.focus();
					this.close();
				}
			},
			_hasSpace(position) {
				/* Temporarily add the position class before we check spacing. */
				const positionClass = this._getPositionClass(position);
				this.$wrap.addClass(positionClass);
				const element = this._getLocation();
				const wrap = this._getLocation(this.$wrap);
				const win = this._getLocation($(window));
				const restrictions = {
					bottom() {
						const viewportBottom = win.height + win.scrollTop - win.padding;
						const spaceAvailable = viewportBottom - element.bottom;
						return spaceAvailable > wrap.height;
					},
					left() {
						const viewportLeft = win.padding + win.scrollLeft;
						const spaceAvailable = element.left - viewportLeft;
						return spaceAvailable > wrap.width;
					},
					right() {
						const viewportRight = win.padding + win.scrollLeft + win.width;
						const spaceAvailable = viewportRight - element.right;
						return spaceAvailable > wrap.width;
					},
					top() {
						const viewportTop = win.padding + win.scrollTop;
						const spaceAvailable = element.top - viewportTop;
						return spaceAvailable > wrap.height;
					}
				};
				/* Remove temporary class. */
				this.$wrap.removeClass(positionClass);
				return restrictions[position]();
			},
			_initClasses() {
				const config = this.config;
				this.$element.addClass('calloutTrigger');
				/* Set callout style */
				if (config.style === 'dark') {
					this.$wrap.addClass('bgDark calloutStyleDark');
				} else if (config.style === 'alt') {
					this.$wrap.addClass('calloutStyleAlt');
				}
				/* Add custom classes */
				if (config.classes) {
					this.$content.addClass(config.classes);
				}
				if (config.calloutClasses) {
					this.$wrap.addClass(config.calloutClasses);
				}
				/* Show / hide pointer */
				if (config.hidePointer) {
					this.$wrap.addClass('calloutHidePointer');
				}
				/* Callout type */
				if (config.type === 'field') {
					this.$wrap.addClass('calloutTypeField');
				} else if (config.type === 'guidance') {
					this.$wrap.addClass('guidance');
					this.$element.addClass('guidanceTrigger');
				}
			},
			_inView() {
				const element = this._getLocation();
				const container = this._getLocation(this.$scrollable);
				const above = element.middle < container.top;
				const below = element.middle > container.bottom;
				const left = element.center < container.left;
				const right = element.center > container.right;
				return !above && !below && !left && !right;
			},
			_isFocusWithinCallout() {
				return $(document.activeElement).closest(this.$wrap).length;
			},
			open() {
				if (this.isOpen) {
					return;
				}
				const config = this.config;
				/* Don't close other callouts when opening a guidance callout. */
				if (config.type !== 'guidance') {
					/* Don't close guidance callouts. */
					$.callout.close(false);
				}
				this.inModal = this.$element.closest('#modal').length > 0;
				this._addOpenEvents();
				if (this.inModal) {
					/* Move #callout into modal. */
					this.$callout.appendTo('.modalFlex.open');
				}
				if (ui.isValidCallback(config.onOpen)) {
					config.onOpen.apply(this.$element, [this.$element]);
				}
				/* We need callout to be displayed (but not visible) so that our position calculations are accurate. */
				this.$wrap.addClass('open unpositioned');
				if (!this.inModal) {
					/* Don't use willTransform inside of modal. It prevents callout positioning from triggering repaints. The modal won't recognize callout position without a repaint. */
					this.$wrap.addClass('willTransform');
				}
				if (!config.hidePointer) {
					this.$pointer.addClass('willTransform');
				}
				this.$element.addClass('active');
				if (config.type !== 'field') {
					this.$element.attr('aria-expanded', true);
				}
				this.position();
				this._handleAutoFocus();
				this.isOpen = true;
				this.$element.trigger('opened.callout', this.$element);
				if (ui.isValidCallback(config.onAfterOpen)) {
					config.onAfterOpen.apply(this.$element, [this.$element]);
				}
			},
			_parseContent() {
				let content = this.config.content;
				if (typeof content === 'string') {
					try {
						/* Convert content into jQuery object. This will throw an error if a string contains unrecognized characters. */
						const $content = $(content);
						const firstChar = content.substring(0, 1);
						const lastChar = content.substring(content.length - 1, content.length);
						if ($content.closest('body').length) {
							/* Content is a selector. Specified dom element found. */
							content = $content;
							this._createPlaceholder($content);
						} else if (firstChar !== '<' || lastChar !== '>') {
							/* Content is a plain string with no unrecognized characters. */
							content = `<div>${content}</div>`;
						}
					} catch (e) {
						/* Content is a plain string with unrecognized characters. */
						content = `<div>${content}</div>`;
					}
				} else if (content.jquery && content.closest('body').length) {
					/* Content is a jQuery object in the DOM */
					this._createPlaceholder(content);
				}
				return content;
			},
			position(position = this.config.position) {
				this._resetPosition();
				this._setWidth();
				this.modalOffset = this._getModalOffset();
				const bestPosition = this._getBestPosition(position);
				this.$wrap.addClass(this._getPositionClass(bestPosition));
				if (this.config.type === 'cursor') {
					this.$element
						.off('mousemove.callout')
						.on('mousemove.callout', ui.throttle(e => {
							const coords = { pageX: e.pageX, pageY: e.pageY };
							this._applyPosition(bestPosition, coords);
						}, 16));
					/* Every 16 ms === 60 fps. */
				} else {
					this._applyPosition(bestPosition);
				}
			},
			_removeCreateEvents() {
				this.$element.off('.callout.create');
			},
			_removeOpenEvents() {
				this.$element.off('.callout.open');
				this.$wrap.add(this.$content).off('.callout');
				$('html').add(window).off(`.callout.${this.uniqueId}`);
				if (this.$scrollable.length) {
					this.$scrollable.off(`.callout.${this.uniqueId}`);
				}
			},
			_resetPosition() {
				this.$wrap
					.removeClass('calloutPositionBottom calloutPositionLeft calloutPositionRight calloutPositionTop')
					.add(this.$content)
					.add(this.$pointer)
					.removeAttr('style');
			},
			_restorePlaceholder() {
				const $placeholder = $(`#calloutPlaceholder-${this.uniqueId}`);
				if ($placeholder.length) {
					$placeholder.replaceWith($(this.config.content));
				}
			},
			_setWidth() {
				const maxWidth = parseInt(this.$wrap.css('max-width'), 10);
				const availableWidth = $(window).outerWidth(false) - (2 * callout.staticProperties.windowPadding);
				if (maxWidth > availableWidth) {
					this.$wrap.css('max-width', availableWidth);
				}
				if (this.config.width) {
					this.$wrap.css('width', this.config.width);
				}
			},
			_startCloseTimer() {
				this.closeTimeout = setTimeout(() => {
					if (!this.hovering && this.isOpen) {
						this.close();
					}
				}, callout.staticProperties.hideDelay);
			},
			_stopCloseTimer() {
				if (this.closeTimeout) {
					clearTimeout(this.closeTimeout);
					this.closeTimeout = null;
				}
			},
			updateContent(content) {
				this._restorePlaceholder();
				this.config.content = content;
				this.$content.html(this._parseContent());
				if (this.isOpen) {
					this.position();
				}
			},
			updateWidth(width) {
				this.config.width = width;
				if (this.isOpen) {
					this.position();
				}
			}
		},
		staticProperties: {
			defaults: {
				calloutClasses: '', /* Class(es) to add to .callout (separate with space, no dot) */
				classes: '', /* Class(es) to add to .calloutContent (separate with space, no dot) */
				content: '', /* Accepts string / text, HTML, or element selector */
				disableAutofocusOnOpen: false, /* Boolean. Set to true to manually handle focus on open */
				hidePointer: false, /* Set to true to hide pointer */
				onClose: false, /* function ($trigger) { } Callback to run *before* callout closes */
				onOpen: false, /* function ($trigger) { } Callback to run *before* callout opens */
				position: 'bottom', /* Position of callout relative to trigger: 'bottom' (default) | 'top' | 'left' | 'right' */
				style: 'light', /* 'light' (default) | 'dark' | 'alt' */
				type: 'click', /* 'click' (default) | 'hover' | 'cursor' (follows mouse) | 'field' | 'guidance' | 'custom' (bind your own events) */
				width: false
			},
			hideDelay: 100, /* Hide delay time in ms for 'hover' callouts */
			langSets: {},
			windowPadding: 5 /* Space callouts leave on each side of window */
		}
	};
	$.fn.callout = function init(options, ...args) {
		const isMethodCall = typeof options === 'string'; /* If options is a string, we assume it refers to a method. */
		let returnValue = this;
		this.each((i, el) => {
			const $el = $(el);
			if (isMethodCall) {
				const instance = $el.data('calloutInstance');
				if (!instance) {
					ui.warn(`Cannot call methods on callout prior to initialization; attempted to call method "${options}".`);
					return false;
				}
				if (!$.isFunction(instance[options]) || options.indexOf('_') === 0) {
					ui.warn(`No such method "${options}" for callout.`);
					return false;
				}
				const methodValue = instance[options](...args);
				if (methodValue !== instance && methodValue !== undefined) {
					returnValue = methodValue && methodValue.jquery ? returnValue.pushStack(methodValue.get()) : methodValue;
					return false;
				}
			} else {
				callout.create($el, options);
			}
		});
		return returnValue;
	};
	const autoInit = () => $('[data-callout]:not([data-auto-instantiate="off"])').callout();
	$.callout = {
		close(includeGuidance = true) {
			if (includeGuidance && $.guidance) {
				$.guidance.close();
			}
			const $active = $('.calloutTrigger.active:not(\'.guidanceTrigger\')');
			if ($active.length) {
				$active.callout('close');
			}
		},
		getTest() {
			return Object.assign({}, { autoInit }, callout);
		}
	};
	$(() => autoInit());
})(jQuery);

/****************************************
	Focus Outline
*****************************************/
jQuery(($ => {
	const $html = $('html');
	const keyboard = e => {
		if (e.keyCode === ui.keyCodes.tab) {
			$html
				.removeClass('mouseEvents')
				.off('keydown.outline')
				.one('mousedown.outline', mouse); // eslint-disable-line no-use-before-define
		}
	};
	const mouse = () => {
		$html
			.addClass('mouseEvents')
			.on('keydown.outline', keyboard);
	};
	$html.one('mousedown.outline', mouse);
})(jQuery));

($ => {
	if ($.validator) {
		ui.warn('ERROR: You are including form.js multiple times');
		return;
	}
	const validator = {
		create($element, options) {
			let instance = $element.data('validatorInstance');
			if (instance) {
				instance.destroy();
			}
			instance = Object.create(this.proto);
			instance.config = Object.assign({}, this.staticProperties.defaults, options);
			instance.$element = $element;
			instance.allowSubmit = true;
			instance.config.submitButton = instance.config.submitButton || $element.find('[type="submit"]').first();
			instance.addFields(instance.config.fields, false);
			instance._prettifyFileInputs();
			instance._bindEvents();
			$element
				.attr('novalidate', 'novalidate') /* Disable native browser validation */
				.data('validatorInstance', instance);
			return instance;
		},
		proto: {
			addFields(fields, modifying = true) {
				for (const id in fields) {
					if (!fields.hasOwnProperty(id)) {
						continue;
					}
					const elementCheck = document.getElementById(id);
					if (!elementCheck) {
						ui.warn(`Unable to find element '#${id}' for validatior`);
						continue;
					}
					if (modifying) {
						if (this.config.fields[id]) {
							this._destroyField(id);
						}
						this.config.fields[id] = fields[id];
					}
					if (elementCheck.tagName.toLowerCase() === 'input' && elementCheck.type === 'file') {
						const labelCheck = document.querySelectorAll(`label[for=${id}].fileBtn`);
						if (labelCheck.length === 0) {
							this._prettifyFileInputs(id, elementCheck);
						}
					}
					this._initField(id, fields[id]);
				}
			},
			_bindEvents() {
				const fieldElements = 'input, label, legend, select, textarea';
				this.$element
					/* Ability to allow for a delayed callback to display the correct error message */
					.on('validation-message.form', fieldElements, (e, isValid, indexOfErrorMessage) => {
						const fieldConfig = this.config.fields[e.currentTarget.id];
						if (!fieldConfig) {
							return false;
						}
						fieldConfig.$label.attr('data-error-index', indexOfErrorMessage);
						this._updateValidationMessage(e.currentTarget.id, isValid);
					})
					/* Ability to dynamically set error index on a field while in a custom pattern */
					.on('validation-error-index.form', fieldElements, (e, index) => {
						const fieldConfig = this.config.fields[e.currentTarget.id];
						if (!fieldConfig) {
							return false;
						}
						fieldConfig.$label.attr('data-error-index', index);
					})
					.on('validation-remove.form', fieldElements, e => {
						const fieldConfig = this.config.fields[e.currentTarget.id];
						if (!fieldConfig) {
							return false;
						}
						this._destroyField(e.currentTarget.id);
					})
					.on('validation.form', fieldElements, e => {
						const fieldConfig = this.config.fields[e.currentTarget.id];
						if (!fieldConfig) {
							return false;
						}
						this._validateInput(e.currentTarget.id, e);
					})
					.on('submit.form', () => {
						const validation = this._validateForm();
						if (validation.success && this.allowSubmit) {
							this.allowSubmit = false;
							/* Disabled inputs are not submitted, so don't disable until after submission. */
							setTimeout(() => {
								this.config.submitButton.attr('disabled', 'disabled').addClass('disabled');
							});
							if (this.config.allowMultipleSubmissions) {
								setTimeout(() => {
									this.allowSubmit = true;
									this.config.submitButton.removeAttr('disabled').removeClass('disabled');
								}, 1000); /* Preventing accidental double submission by delaying for 1 second */
							}
							return true;
						}
						return false;
					});
			},
			_createOrUpdatePasswordCallout($field) {
				const openCallout = $('.callout.open');
				const props = validator.staticProperties;
				if (this.passwordRules && openCallout.length && openCallout.find('.calloutMenu[id^="password-rules"]').length && openCallout.find('.calloutContent').length) {
					this.$passwordRulesList.attr('role', 'alert'); // alert is required rather than status for NVDA to read update
					openCallout.find('.calloutContent').html(this.$passwordRulesList); // Element must be re-injected for VoiceOver to pick up new live-region role
					props.passPolicy.Rules.forEach((r, i) => {
						if (r.Regex) {
							const exp = new RegExp(r.Regex);
							const valid = exp.test($field.val());
							const $elm = this.passwordRules[i];
							const wasValid = $elm.hasClass('calloutMenuChecked');
							if (valid !== wasValid) {
								$elm.toggleClass('calloutMenuChecked iconAfter iconAfterCheck calloutMenuUnchecked');
								const validText = ui.localize(props.langSets, valid ? 'policyMet' : 'policyNotMet');
								$elm.find('.hideVisually').html(validText);
							}
						}
					});
				} else {
					this.passwordRules = [];
					const $passwordRulesList = $(`<div class="calloutMenu" id="password-rules-${ui.getRandomNumber()}">`);
					props.passPolicy.Rules.forEach(rule => {
						if (rule.Regex) {
							const exp = new RegExp(rule.Regex);
							const valid = exp.test($field.val());
							const validText = ui.localize(props.langSets, valid ? 'policyMet' : 'policyNotMet');
							const $elm = $('<div class="calloutMenuItem">');
							if (valid) {
								$elm.addClass('calloutMenuChecked iconAfter iconAfterCheck');
							} else {
								$elm.addClass('calloutMenuUnchecked');
							}
							$elm.append(`<span class="hideVisually">${validText}</span>${rule.FailureCode_locale.LocaleString}<span class="hideVisually"> - </span>`);
							this.passwordRules.push($elm);
							$passwordRulesList.append($elm);
						} else {
							const $elm = $('<div class="calloutMenuUnchecked calloutMenuItem">');
							$elm.append(`<span class="hideVisually">${ui.localize(props.langSets, 'policyNotMet')}</span>${rule.FailureCode_locale.LocaleString}<span class="hideVisually"> - </span>`);
							this.passwordRules.push($elm);
							$passwordRulesList.append($elm);
						}
					});
					this.$passwordRulesList = $passwordRulesList;
					$field.callout({ content: $passwordRulesList, classes: 'errorCallout passwordValidationMenu', style: 'light' }).callout('open');
				}
			},
			destroy() {
				const fields = this.config.fields;
				for (const id in fields) {
					if (!fields.hasOwnProperty(id)) {
						continue;
					}
					this._destroyField(id);
				}
				this.$element
					.off('.form')
					.removeData('validatorInstance');
			},
			_destroyField(id) {
				const fieldConfig = this.config.fields[id];
				const $field = fieldConfig.$field;
				if ($field) {
					this._removeErrorAndSuccess(id);
					$field
						.off('.form')
						.removeAttr('aria-labelledby');
				}
				if (fieldConfig.collection) {
					fieldConfig.collection.removeAttr('aria-labelledby');
				}
				this.config.fields[id] = null;
				delete this.config.fields[id];
			},
			_getPasswordRules() {
				if (Object.keys(validator.staticProperties.passPolicy).length === 0) {
					const getPasswordRulesURL = 'https://gateway.ancestry.com/passwordpolicy/web/v1.0/policies/acom?client=YzU1MjlmMTQzNDg0OTQyMzM1YzUzNDk2MWVhNjQ4ZWViNDM1M2ZhNys3OGRkMTRmZTJlNDFlYjMzZDNkZTVlMmQ3NGM2OTRmY2Q2NjFjODA0MWIyZDdlZTdiZjA3Zjk3NjRjYzAxOTRl';
					const $langElement = this.$element.closest('[lang]');
					validator.staticProperties.langHeader = $langElement.length ? $langElement.attr('lang') : 'en';
					$.ajax({
						beforeSend(xhr) {
							xhr.setRequestHeader('Accept-Language', validator.staticProperties.langHeader);
						},
						dataType: 'json',
						success: data => {
							validator.staticProperties.passPolicy = data;
						},
						url: getPasswordRulesURL
					});
				}
			},
			_initField(id, restrictions) {
				let $field = this.$element.find(`#${id}`);
				if (!$field) {
					return;
				}
				const fieldConfig = this.config.fields[id];
				const isPasswordField = fieldConfig.pattern === 'password';
				if (isPasswordField) {
					this._getPasswordRules();
					fieldConfig.errorLocation = 'callout';
					fieldConfig.when = 'input';
					$field
						.off('focus.form')
						.on('focus.form', e => {
							this._validateInput(id, e);
						})
						.off('blur.form')
						.on('blur.form', () => {
							if ($('.callout.open').length) {
								$field.callout('destroy');
							}
						});
				}
				let $label = $(`label[for="${id}"]`).first();
				if (!$label.length) {
					const $fieldset = $field.closest('fieldset');
					$label = $fieldset ? $fieldset.find('legend') : $field;
				}
				if ($field.is('legend')) {
					/*	To validate checkboxes and radios, you have
						to pass in the ID of the legend element */
					$label = $field;
					$field = restrictions.collection;
					restrictions.when = restrictions.when !== 'submit' ? 'blur change' : 'submit';
				} else if ($field.is('[type="checkbox"]')) {
					/*	When a single checkbox needs validation we need to set that checkbox as the
						collection to later be able to validate if it has been checked or not. */
					restrictions.collection = $field;
					restrictions.when = restrictions.when !== 'submit' ? 'blur change' : 'submit';
				} else if ($field.is('[type="file"]')) {
					restrictions.when = restrictions.when || 'change';
				} else if (!restrictions.when) {
					restrictions.when = 'blur';
				}
				if (restrictions.required === true) {
					$field.attr('aria-required', true);
				}
				/*	Bind the validation to the event from the settings
					for this field. Default event is on blur */
				if (restrictions.when !== 'submit') {
					let namespacedEvents = restrictions.when.split(' ').join('.form ');
					namespacedEvents += '.form';
					if (isPasswordField) {
						this.debouncePasswordCheck = ui.debounce(() => {
							this._validateOnServer($field.val(), this, b => this._updateValidationMessage(id, b));
						}, 600);
					}
					$field.on(namespacedEvents, e => {
						this._validateInput(id, e);
					});
				}
				fieldConfig.$field = $field;
				fieldConfig.$label = $label;
				/*	Show all server side validation and validate all fields with value on load. */
				if ($label.attr('data-error-index')) {
					this._updateValidationMessage(id, false);
				} else if ($field.val() && !$field.is('[type="checkbox"], [type="radio"], [type="submit"]')) {
					this._updateValidationMessage(id, !!this._isValid(id));
				}
			},
			_isValid(fieldId, e) {
				const fieldConfig = this.config.fields[fieldId];
				const $field = fieldConfig.$field;
				let value = $field.val().trim();
				/* Validate file fields based of array of file names rather than input value */
				if ($field.is('[type="file"]')) {
					value = $.map($field[0].files, file => file.name);
				}
				if (ui.isValidCallback(fieldConfig.pattern)) {
					return fieldConfig.pattern(value, $field, !!fieldConfig.required, e || { type: 'validation' });
				}
				/* Validate checkboxes and radios */
				if ($field.is('[type="checkbox"], [type="radio"]')) {
					return this._validateCollection(fieldConfig.collection, fieldConfig.required);
				}
				if (fieldConfig.required || value.length) {
					if (!value.length ||
						(fieldConfig.extensions && !$.validator.extensions(value, fieldConfig.extensions)) ||
						(fieldConfig.match && !$.validator.match(value, fieldConfig.match)) ||
						(fieldConfig.minLength && !$.validator.minLength(value, fieldConfig.minLength)) ||
						(fieldConfig.maxLength && !$.validator.maxLength(value, fieldConfig.maxLength))) {
						if (fieldConfig.pattern === 'password') {
							this.isPasswordValidFromServer = false;
						}
						return false;
					}
					/* validate string based patterns (such as url, email, etc) */
					if (fieldConfig.pattern) {
						return $.validator[fieldConfig.pattern](value, this);
					}
				}
				return true;
			},
			_prependProtocol(fieldId) {
				const $field = this.config.fields[fieldId].$field;
				const val = $field.val();
				if (val && val.indexOf('://') === -1) {
					$field.val(`http://${val}`);
				}
			},
			_prettifyFileInputs() {
				this.$element
					.find('input[type="file"]')
					.each((i, el) => {
						const $field = $(el);
						if ($field.data('usesFilePolyfill')) {
							return true;
						}
						const chooseFiles = ui.localize(validator.staticProperties.langSets, el.hasAttribute('multiple') ? 'chooseFiles' : 'chooseFile');
						$field
							.data('usesFilePolyfill', true)
							.after(`<label class="ancBtn fileBtn silver" for="${$field.attr('id')}">${chooseFiles}</label><span class="filename"></span>`)
							.on('change.polyfill', () => {
								const fileNames = $.map(el.files, file => file.name).join(', ');
								$field.siblings('.filename').html(fileNames);
							});
					});
			},
			_removeErrorAndSuccess(fieldId) {
				const fieldConfig = this.config.fields[fieldId];
				const $field = fieldConfig.$field;
				$field
					.removeClass('error success inform')
					.removeAttr('aria-invalid');
				fieldConfig.$label.removeClass('error success inform');
				if (fieldConfig.collection) {
					fieldConfig.collection.removeClass('error success inform');
				}
				if (fieldConfig.$errorMessage) {
					fieldConfig.$errorMessage.remove();
					fieldConfig.$errorMessage = null;
				}
				if (fieldConfig.hasErrorCallout && jQuery().callout && fieldConfig.pattern !== 'password') {
					$field.callout('destroy');
					fieldConfig.hasErrorCallout = false;
					if ($field.attr('data-callout')) {
						$field.callout();
					}
				}
			},
			removeFields(fieldIds) {
				const fields = typeof fieldIds === 'string' ? [fieldIds] : fieldIds;
				for (let i = 0, len = fields.length; i < len; i++) {
					this._destroyField(fields[i]);
				}
			},
			_updateValidationMessage(fieldId, isValid) {
				const fieldConfig = this.config.fields[fieldId];
				const $label = fieldConfig.$label;
				const $field = fieldConfig.$field;
				this._removeErrorAndSuccess(fieldId);
				/*	Leave as `=== true` and `=== false` so it's possible to pass in null/
					undefined/'delay'/'clear'/'info' to simply clear the validation message */
				if (isValid === true) {
					if (fieldConfig.collection) {
						fieldConfig.collection
							.removeAttr('aria-invalid aria-labelledby')
							.addClass('success');
					}
					$field
						.removeAttr('aria-invalid aria-labelledby')
						.addClass('success');
					if (fieldConfig.pattern === 'password' && $('.callout.open').length) {
						$field.callout('destroy');
					}
					$label.addClass('success');
				} else if (isValid === false || isValid === 'info') {
					let labelID = $label.attr('id');
					if (!labelID) {
						labelID = `label-${ui.getRandomNumber()}`;
						$label.attr('id', labelID);
					}
					const errorClass = isValid === 'info' ? 'inform' : 'error';
					const errorId = `${labelID}ErrorMessage`;
					if (fieldConfig.collection) {
						fieldConfig.collection.each((i, el) => {
							const $thisField = $(el);
							const thisFieldId = $thisField.attr('id');
							const $thisLabel = $(`label[for="${thisFieldId}"]`);
							let thisLabelID = $thisLabel.attr('id');
							if (!thisLabelID) {
								thisLabelID = `label-${ui.getRandomNumber()}`;
								$thisLabel.attr('id', thisLabelID);
							}
							setTimeout(() => {
								$thisField.attr('aria-labelledby', `${thisLabelID} ${errorId}`);
							}, 50); /* Delay this attr by 50ms to prevent screenreader reading it twice */
						});
					} else {
						setTimeout(() => {
							$field.attr('aria-labelledby', `${$label.attr('id')} ${errorId}`);
						}, 50); /* Delay this attr by 50ms to prevent screenreader reading it twice */
					}
					$field
						.addClass(errorClass)
						.attr('aria-invalid', true);
					$label.addClass(errorClass);
					const whichMessage = $label.attr('data-error-index') || 0;
					const errorMessage = $.trim(($label.attr('data-error') || '').split('|')[whichMessage]);
					let $errorMessage;
					if (fieldConfig.errorLocation === 'callout' && jQuery().callout) {
						$errorMessage = $('<span class="errorMessage icon iconWarning" />');
						$label.append($errorMessage);
						if (!fieldConfig.collection) {
							fieldConfig.hasErrorCallout = true;
							if (errorMessage !== '') {
								$field
									.callout({
										content: errorMessage,
										classes: 'errorCallout'
									});
							}
							const isPasswordField = fieldConfig.pattern === 'password';
							if (isPasswordField && validator.staticProperties.passPolicy.Rules) {
								if (document.activeElement === $field[0] && !this.isPasswordValidFromServer) {
									this._createOrUpdatePasswordCallout($field);
								}
							} else {
								if ($('.callout.open').length) {
									$field.callout('destroy');
								}
								$field.callout({ content: errorMessage, classes: `errorCallout ${isPasswordField ? 'passwordValidationMenu' : ''}`, calloutStyle: `${isPasswordField ? 'light' : 'dark'}` }).callout('open');
							}
						}
						const $accessibleErrorMessage = $(`<div class="noDisplay" id="${errorId}">${errorMessage}</div>`);
						$label
							.parent()
							.append($accessibleErrorMessage);
						$errorMessage = $errorMessage.add($accessibleErrorMessage);
					} else if (fieldConfig.errorLocation === 'below' || fieldConfig.errorLocation === 'callout') {
						$errorMessage = $(`<div class="errorMessage icon iconWarning" id="${errorId}">${errorMessage}</div>`);
						$label
							.parent()
							.append($errorMessage);
					} else {
						$errorMessage = $(`<span class="errorMessage icon iconWarning">${errorMessage}</span>`);
						if ($label.text().indexOf(errorMessage) === -1) {
							$label.append($errorMessage);
						}
					}
					fieldConfig.$errorMessage = $errorMessage;
				}
				$label.removeAttr('data-error-index');
			},
			_validateCollection($collection, required) {
				return Number(required) <= $collection.filter(':checked').length;
			},
			_validateForm() {
				let $firstErrorLabel = false;
				let $firstErrorInput = false;
				const validation = {
					success: true,
					errorFieldLabels: []
				};
				const fields = this.config.fields;
				for (const id in fields) {
					if (!fields.hasOwnProperty(id)) {
						continue;
					}
					const fieldConfig = fields[id];
					const $field = fieldConfig.$field;
					const $label = fieldConfig.$label;
					/*	If an element was dynamically removed, delete
						it from the validation list and continue on */
					if (!$field || $field.length === 0) {
						ui.warn(`Unable to find field '#${id}' for validator`);
						fields[id] = null;
						delete fields[id];
						continue;
					}
					const result = this._validateInput(id);
					if (!result || result === 'info') {
						if ($firstErrorLabel === false) {
							$firstErrorLabel = $label;
							$firstErrorInput = $field;
						}
						validation.success = false;
					}
				}
				if (!validation.success && $firstErrorLabel) {
					const $document = $(document);
					const scrollLocationForFirstError = $firstErrorLabel.offset().top;
					/* Only scroll if not at top of page. */
					if (scrollLocationForFirstError < ($('html').scrollTop() || $document.scrollTop())) {
						$document.scrollTop(scrollLocationForFirstError);
					}
					$firstErrorInput.focus();
				}
				if (ui.isValidCallback(this.config.onSubmit)) {
					/*	To cancel submit event on form, a boolean
						is required. This line allows external onSubmit
						function to return a truthy or falsy response */
					validation.success = !!this.config.onSubmit(validation.success, this.$element);
				}
				return validation;
			},
			_validateInput(fieldId, e) {
				if (this.config.fields[fieldId].pattern === 'url') {
					this._prependProtocol(fieldId);
				}
				const result = this._isValid(fieldId, e);
				if (result === 'delay' || result === 'info') {
					this._updateValidationMessage(fieldId, result);
				} else if (!result) {
					this._updateValidationMessage(fieldId, false);
				} else if (this.config.fields[fieldId].$field.val()) {
					this._updateValidationMessage(fieldId, true);
				} else {
					this._removeErrorAndSuccess(fieldId);
				}
				return result;
			},
			_validateOnServer(value, instance, callback) {
				const postPasswordRulesURL = 'https://gateway.ancestry.com/passwordpolicy/web/v1.0/validate?client=YzU1MjlmMTQzNDg0OTQyMzM1YzUzNDk2MWVhNjQ4ZWViNDM1M2ZhNys3OGRkMTRmZTJlNDFlYjMzZDNkZTVlMmQ3NGM2OTRmY2Q2NjFjODA0MWIyZDdlZTdiZjA3Zjk3NjRjYzAxOTRl';
				const dataToPost = JSON.stringify({
					Password: value,
					PolicyId: 'acom'
				});
				const successFunction = data => {
					instance.isPasswordValidFromServer = !!data.IsValid;
					callback(instance.isPasswordValidFromServer);
				};
				$.ajax({
					beforeSend(xhr) {
						xhr.setRequestHeader('Accept-Language', validator.staticProperties.langHeader);
					},
					data: dataToPost,
					dataType: 'json',
					success: successFunction,
					type: 'POST',
					url: postPasswordRulesURL
				}).fail(() => callback(true));
			}
		},
		staticProperties: {
			defaults: {
				allowMultipleSubmissions: false, /* BOOLEAN, allows ajax forms to be submitted multiple times, default is false */
				submitButton: null, /* jQuery OBJECT, default $form.find('input[type="submit"]') */
				onSubmit: false, /* FUNCTION, callback ran right before the form submit event. Return false to stop form submission. */
				fields: {}
				/*	The fields object contains a group of objects. Each key should be the field ID with its corresponding object using these options:
					'collection': jQuery Object, used for radio/checkbox inputs. Select each input in the group. i.e. $('input[name="interests[]"]')
					'errorLocation': STRING, where to put the error message. - null, 'below', 'callout'
					'pattern': STRING or FUNCTION - 'number', 'url', 'tel', 'email', function(value, $field) { ... return true || false; }
					'match': STRING, pass in ID of the field to match. i.e. password field
					'maxLength': INTEGER, max number of characters allowed in field
					'minLength': INTEGER, min number of characters allowed in field
					'required': BOOLEAN or INTEGER - whether field is required or number of checkboxes that must be selected
					'extensions': ARRAY of STRINGs, allowed file extentions for input[type="file"]. Must be lowercase. (i.e. ['png', 'jpg', 'gif'])
					'when': STRING, when to validate - any event. Default is on blur. */
			},
			langSets: {
				de: {
					chooseFile: 'Datei auswählen',
					chooseFiles: 'Wähle Dateien',
					errorMsg: 'Es liegen Feldfehler vor:',
					policyMet: 'Richtlinie eingehalten:',
					policyNotMet: 'Richtlinie nicht eingehalten:'
				},
				en: {
					chooseFile: 'Choose file',
					chooseFiles: 'Choose files',
					errorMsg: 'There are errors on these fields:',
					policyMet: 'Policy met:',
					policyNotMet: 'Policy not met:'
				},
				es: {
					chooseFile: 'Selecciona un archivo',
					chooseFiles: 'Seleccionar archivos',
					errorMsg: 'Hay campos con errores:',
					policyMet: 'Se cumple la política:',
					policyNotMet: 'No se cumple la política:'
				},
				fr: {
					chooseFile: 'Choisir le fichier',
					chooseFiles: 'Choisir les fichiers',
					errorMsg: 'Il y a d’erreurs dans ces champs:',
					policyMet: 'Exigences satisfaites :',
					policyNotMet: 'Exigences non satisfaites :'
				},
				it: {
					chooseFile: 'Scegli file',
					chooseFiles: 'Scegli file',
					errorMsg: 'Ci sono errori in questi campi:',
					policyMet: 'Normativa soddisfatta:',
					policyNotMet: 'Normativa non soddisfatta:'
				},
				sv: {
					chooseFile: 'Välj fil',
					chooseFiles: 'Välja filer',
					errorMsg: 'Det uppstod fel för dessa fält:',
					policyMet: 'Policy uppfylls:',
					policyNotMet: 'Policy uppfylls inte:'
				}
			},
			passPolicy: {}
		}
	};
	$.fn.validator = function init(options, ...args) {
		const isMethodCall = typeof options === 'string'; /* If options is a string, we assume it refers to a method. */
		if (isMethodCall) {
			const instance = this.data('validatorInstance');
			if (!instance) {
				ui.warn(`Cannot call methods on validator prior to initialization; attempted to call method "${options}".`);
				return false;
			}
			if (!$.isFunction(instance[options]) || options.indexOf('_') === 0) {
				ui.warn(`No such method "${options}" for validator.`);
				return false;
			}
			const methodValue = instance[options](...args);
			if (methodValue !== instance && methodValue !== undefined) {
				return methodValue && methodValue.jquery ? this.pushStack(methodValue.get()) : methodValue;
			}
		} else {
			validator.create(this, options);
		}
		return this;
	};
	$.validator = {
		extensions(fileNames, validExtensions) {
			const files = typeof fileNames === 'string' ? [fileNames] : fileNames;
			const lowerCasedValidExtensions = validExtensions.map(ext => ext.toLowerCase());
			for (let i = 0, len = files.length; i < len; i++) {
				if (lowerCasedValidExtensions.indexOf(files[i].toLowerCase().split('.').pop()) === -1) {
					return false; /* If any file does not have the correct extension, full function is invalid. */
				}
			}
			return true;
		},
		email(value) {
			return /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/.test(value);
		},
		getTest() {
			return Object.assign({}, validator);
		},
		match(value, matchId) {
			return value === $(`#${matchId}`).val();
		},
		maxLength(value, maxLength) {
			return value.length <= maxLength;
		},
		minLength(value, minLength) {
			return value.length >= minLength;
		},
		number(value) {
			return /^[0-9]*(\.[0-9]+)?$/.test(value);
		},
		password(value, instance) {
			let returnValue = true;
			let hasNonRegex = false;
			const rules = validator.staticProperties.passPolicy.Rules;
			if (rules) {
				rules.forEach(r => {
					if (r.Regex) {
						const exp = new RegExp(r.Regex);
						returnValue = returnValue && exp.test(value);
					} else {
						hasNonRegex = true;
					}
				});
				if (returnValue) {
					if (hasNonRegex) {
						returnValue = instance.isPasswordValidFromServer;
						if (instance.debouncePasswordCheck) {
							instance.debouncePasswordCheck();
						}
					}
				} else {
					instance.isPasswordValidFromServer = false;
				}
			}
			return returnValue;
		},
		tel(value) {
			return /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(value);
		},
		url(value) {
			return /^(http|https|ftp):\/\/(([A-Z0-9][A-Z0-9_-]*)(\.[A-Z0-9][A-Z0-9_-]*)+)(:(\d+))?\/?/i.test(value);
		},
		/*	The caching approach to get the password rules makes it so you can't change the language
			after the first field has been instantiated. This exposes a method to clear that cache. */
		uncachePasswordRules() {
			validator.staticProperties.passPolicy = {};
		}
	};
})(jQuery);

/****************************************
	Modal
*****************************************/
($ => {
	if ($.modal) {
		ui.warn('ERROR: You are including modal.js multiple times');
		return;
	}
	const modal = {
		create($element, options) {
			if ($element === undefined || $element.length === 0) {
				ui.warn('Modal contents not found. Check your selector.');
				return false;
			}
			let instance = $element.data('modalInstance');
			if (instance) {
				instance.destroy();
			}
			const $body = $('body');
			if (!$('#modal').length) {
				$body.append('<section id="modal"><div class="modalFixed" id="modalFixed"></div></section>');
			}
			instance = Object.create(this.proto);
			instance.config = Object.assign({}, this.staticProperties.defaults, options);
			const config = instance.config;
			instance.$element = $element;
			instance.uniqueId = $element.attr('id') || `modal-${ui.getRandomNumber()}`;
			instance.isOpen = false;
			instance.dynamic = !$body.find($element).length;
			instance.iOSFix = window.navigator.userAgent.match(/iPhone|iPad|iPod/i);
			config.useCustomPadding = config.useCustomPadding || config.isMarketing;
			instance._generateMarkup();
			$element.data('modalInstance', instance); /* Save instance to $element */
			if (config.open) {
				instance.open();
			}
			return instance;
		},
		proto: {
			_addEvents() {
				const config = this.config;
				if (config.closeOnBkgClick) {
					this.$modal.on(`click.modal.${this.uniqueId}`, e => {
						/* Allow events to propagate without closing modal */
						if (!this.preventBgClickClose && !this._preventBgClickClose && $(e.target).hasClass('modalFlex')) {
							this.close();
						}
						this._preventBgClickClose = false;
					});
				}
				if (config.closeOnEscape) {
					this.$modal.on(`keydown.modal.${this.uniqueId}`, e => {
						const elmName = e.target.tagName.toLowerCase();
						if (e.which === ui.keyCodes.esc && elmName !== 'input' && elmName !== 'textarea') {
							this.close();
							return false;
						}
					});
				}
				if (this.$close) {
					this.$close.on('click.modal', () => {
						this.close();
					});
				}
				this.$content
					.on('mousedown.modal', () => {
						this._preventBgClickClose = true;
					})
					.on('mouseup.modal', () => {
						this._preventBgClickClose = true;
					})
					.on('keydown.modal', e => {
						/* Keep focus within modal when tabbing */
						if (e.which === ui.keyCodes.tab) {
							const $tabbable = ui.getTabbableElements(this.$content);
							if (!$tabbable.length) {
								return false; /* There is no interactive content */
							}
							const $first = $tabbable.first();
							const $last = $tabbable.last();
							if (e.shiftKey && $first.is(':focus')) {
								$last.focus();
								return false;
							}
							if (!e.shiftKey && $last.is(':focus')) {
								$first.focus();
								return false;
							}
						}
					});
			},
			close() {
				if (!this.isOpen) {
					return;
				}
				this._hideContent();
				if (!this.leaveBackground) {
					this._hideBackground();
				}
			},
			destroy() {
				this.close();
				this.$content
					.removeClass('modalCustomPadding')
					.removeAttr('style');
				this.$flex.removeAttr('style');
				this.$element.removeData('modalInstance');
				if (this.dynamic) {
					this.$flex.remove();
				}
			},
			_generateMarkup() {
				this.$modal = $('#modal');
				this.$fixed = $('#modalFixed');
				this.$element.addClass('modal');
				const config = this.config;
				if ($(`#modal-${this.uniqueId}`).length) {
					/* Use existing containers if they are available */
					this.$flex = $(`#modal-${this.uniqueId}`);
					this.$content = $(`#modalContent-${this.uniqueId}`);
					if (!config.hideCloseBtn) {
						this.$close = this.$content.find('.modalClose');
					}
				} else {
					/* Create new containers */
					this.$flex = $(`<div class="modalFlex" id="modal-${this.uniqueId}" tabIndex="-1" />`);
					this.$content = $(`<div class="modalContents" id="modalContent-${this.uniqueId}" />`).appendTo(this.$flex);
					this.$element.appendTo(this.$content);
					if (!config.hideCloseBtn) {
						const bgDarkClass = this.config.bgDarkClose ? ' bgDark' : '';
						this.$close = $(`<button class="closeBtn modalClose${bgDarkClass}" type="button" />`)
							.attr('title', ui.localize(modal.staticProperties.langSets, 'closeBtn'))
							.appendTo(this.$content);
					}
					this.$fixed.append(this.$flex);
				}
			},
			_hideBackground() {
				$('html').removeClass('modalOpen iOSFix');
				$('body').css('padding-right', '');
				if (this.windowPosition) {
					$('html, body').scrollTop(this.windowPosition);
				}
				this.$modal.removeAttr('style aria-expanded');
				const config = this.config;
				if (this.$initialTrigger) {
					this.$initialTrigger.focus();
				} else if (config.$trigger) {
					config.$trigger.focus();
				}
			},
			_hideContent() {
				this.$flex.removeClass('open');
				this.showLoading(false);
				const config = this.config;
				if (config.onClose && ui.isValidCallback(config.onClose)) {
					config.onClose.apply(this.$element, [this.$element]);
				}
				this.isOpen = false;
				this.$element
					.removeClass('active')
					.trigger('closed.modal');
				this.leaveBackground = false;
				this._removeEvents();
				if (this.dynamic) {
					this.destroy();
				}
			},
			open() {
				if (this.isOpen) {
					return;
				}
				const active = $.modal.getActive();
				if (active && !active.$element.is(this.$element)) {
					active.leaveBackground = true; /* Leave background to prevent flicker and iOSFix class when chaining modals */
					if (active.windowPosition) {
						this.windowPosition = active.windowPosition;
					}
					if (active.$initialTrigger) {
						this.$initialTrigger = active.$initialTrigger || active.config.$trigger;
					}
					active._hideContent();
					this._showContent();
					return;
				}
				this.$initialTrigger = false;
				if (this.iOSFix) {
					this.windowPosition = $(window).scrollTop();
					$('html').addClass('iOSFix');
				}
				this._showBackground();
				this._showContent();
			},
			preventClose(preventClose) {
				let preventCloseValue = preventClose;
				if (preventClose === undefined) {
					preventCloseValue = true;
				}
				this.preventBgClickClose = preventCloseValue;
			},
			_removeEvents() {
				this.$modal.add(window).off(`.modal.${this.uniqueId}`);
				this.$content.add(this.$flex).add(this.$close).off('.modal');
			},
			resize(width) {
				let newWidth = '';
				if (width) {
					if (isNaN(width)) {
						newWidth = width;
					} else {
						const parsedWidth = parseInt(width, 10);
						const padding = this.config.useCustomPadding ? 0 : parseInt(this.$content.css('padding-left'), 10) + parseInt(this.$content.css('padding-right'), 10);
						newWidth = parsedWidth + padding;
					}
				}
				this.$content.css('width', newWidth);
				this.config.width = width;
			},
			_showBackground() {
				const screenWidth = $(window).width();
				let scrollbarWidth = 0;
				const $body = $('body');
				$('html').addClass('modalOpen');
				scrollbarWidth = $(window).width() - screenWidth; /* Overflow has changed, window width may have also changed */
				const padding = parseInt($body.css('padding-right'), 10) + scrollbarWidth;
				$body.css('padding-right', `${padding}px`);
				this.$modal.attr('aria-expanded', 'true');
			},
			_showContent() {
				this._preventBgClickClose = true;
				this.$element.addClass('active');
				this._addEvents();
				const config = this.config;
				this.showLoading(config.showLoading);
				if (config.width) {
					this.resize(config.width);
				}
				if (config.useCustomPadding) {
					this.$content.addClass('modalCustomPadding');
				}
				if (config.hideCloseBtn) {
					this.$content.addClass('modalHideClose');
				}
				if (this.iOSFix) {
					$('html, body').scrollTop(0);
				}
				this.$flex
					.addClass('open')
					.focus();
				this.$fixed.scrollTop(0);
				if (config.onOpen && ui.isValidCallback(config.onOpen)) {
					config.onOpen.apply(this.$element, [this.$element]);
				}
				this.isOpen = true;
				this.$element.trigger('opened.modal');
				const doubleClickBuffer = 300;
				setTimeout(() => {
					this._preventBgClickClose = false;
				}, doubleClickBuffer);
			},
			showLoading(loading) {
				if (loading) {
					this.$content.addClass('loading');
				} else {
					this.$content.removeClass('loading');
				}
			}
		},
		staticProperties: {
			defaults: {
				bgDarkClose: false,
				closeOnBkgClick: true,
				closeOnEscape: true,
				hideCloseBtn: false,
				isMarketing: false,
				onClose: false,
				onOpen: false,
				open: true,
				showLoading: false,
				width: '',
				$trigger: false
			},
			langSets: {
				de: {
					closeBtn: 'Schließen'
				},
				en: {
					closeBtn: 'Close'
				},
				es: {
					closeBtn: 'Cerrar'
				},
				fr: {
					closeBtn: 'Fermer'
				},
				it: {
					closeBtn: 'Chiudi'
				},
				sv: {
					closeBtn: 'Stäng'
				}
			}
		}
	};
	$.fn.modal = function init(options = {}, ...args) {
		/* If options is a string, we assume it refers to a method. */
		const isMethodCall = typeof options === 'string';
		let returnValue = this;
		if (this.length > 1 && !isMethodCall && options.open !== false) {
			ui.warn('Warning: It is recommended to only instantiate a single modal at a time since only the last one is displayed.');
		}
		this.each((i, el) => {
			const $el = $(el);
			if (isMethodCall) {
				const instance = $el.data('modalInstance');
				if (!instance) {
					ui.warn(`Cannot call methods on modal prior to initialization; attempted to call method "${options}".`);
					return false;
				}
				if (!$.isFunction(instance[options]) || options.indexOf('_') === 0) {
					ui.warn(`No such method "${options}" for modal.`);
					return false;
				}
				const methodValue = instance[options](...args);
				if (methodValue !== instance && methodValue !== undefined) {
					returnValue = methodValue && methodValue.jquery ? returnValue.pushStack(methodValue.get()) : methodValue;
					return false;
				}
			} else {
				modal.create($el, options);
			}
		});
		return returnValue;
	};
	$.modal = {
		center() {
			ui.warn('The "$.modal.center" method has been deprecated. Use $.modal.scrollTop(value) when you are transitioning tall content.');
			$.modal.scrollTop(0);
		},
		close() {
			const active = this.getActive();
			if (active) {
				active.close();
			}
		},
		getActive() {
			return $('.modal.active').data('modalInstance');
		},
		getTest() {
			return Object.assign({}, modal);
		},
		preventClosing(preventClose) {
			const active = this.getActive();
			if (active) {
				active.preventClose(preventClose);
			}
		},
		resize(width) {
			const active = this.getActive();
			if (active) {
				active.resize(width);
			}
		},
		scrollTop(position) {
			$('#modalFixed').scrollTop(position || 0);
		},
		showLoading(loading) {
			const active = this.getActive();
			if (active) {
				active.showLoading(loading);
			}
		}
	};
})(jQuery);

/****************************************
	Tab
*****************************************/
($ => {
	if ($.tabs) {
		ui.warn('ERROR: You are including tab.js multiple times');
		return;
	}
	const tabs = {
		create($element, options = {}) {
			let instance = $element.data('tabsInstance');
			if (instance) {
				instance.destroy();
			}
			if ($element.data('allow-urls') === true) {
				options.allowUrls = true;
			}
			instance = Object.create(this.proto);
			instance.config = Object.assign({}, this.staticProperties.defaults, options);
			instance.$element = $element;
			/* Instance state data */
			instance.tabsName = $element.data('tab');
			instance.isVertical = instance._getVertical();
			instance.activeIndex = instance._getActiveIndex();
			instance.overflowBound = false;
			/* Instance elements */
			instance.$tabs = $element.children('.tab');
			instance.$tabGroup = $(`[data-tab-group="${instance.tabsName}"]`);
			instance.$tabContents = instance.$tabGroup.children('.tabContent');
			instance.$tabContents.attr('tabindex', '-1');
			/* Add events */
			$(window).on(`resize.tabs.${instance.tabsName}`, ui.debounce(() => instance._handleResize(), 100));
			$element.on('click.tabs', '.tab', e => instance._handleTabClick(e));
			$element.on('keydown.tabs', '.tab', e => instance._handleTabKeydown(e));
			/* Initialization methods */
			instance._updateTabs();
			instance._applyAriaRoles();
			instance._removeButtonClasses();
			instance._triggerClickOnUrlTab();
			/* Wait 500ms before checking for overflow to ensure styles have loaded */
			instance.delayedCheckOverflow = setTimeout(() => {
				if (typeof instance.overflow === 'undefined') {
					instance._measureWidths();
					instance._checkOverflow();
				}
			}, 500);
			$element.data('tabsInstance', instance);
			return instance;
		},
		proto: {
			_addOverflow() {
				this._createOverflowElements();
				this._bindOverflowEvents();
				this._measureWidths();
				this._centerTab();
			},
			_animateHorizontalScroll(direction) {
				const scrollDistance = Math.ceil(this.elementWidth * 0.75); /* 75% of the width of the element per UX */
				const initialPosition = this.$element.scrollLeft();
				const finalPosition = direction === 'left' ? initialPosition - scrollDistance : initialPosition + scrollDistance;
				this.$element.animate({ scrollLeft: finalPosition }, 250);
			},
			_applyAriaRoles() {
				this.$element.attr('role', this.$element.attr('role') || 'tablist');
				this.$tabs.each((i, el) => {
					const $tab = $(el);
					const dataTab = this.tabsName || `Tabs${ui.getRandomNumber()}`;
					const isTabActive = $tab.hasClass('tabActive');
					const $curTabPanel = this.$tabContents.eq(i);
					const randomId = `${i}-${ui.getRandomNumber()}`;
					const panelId = $curTabPanel.attr('id') || `${dataTab}TabPanel${randomId}`;
					$tab.attr({
						id: $tab.attr('id') || `${this.tabsName}Tab${randomId}`,
						role: $tab.attr('role') || 'tab',
						'aria-controls': $tab.attr('aria-controls') || panelId,
						'aria-selected': $tab.attr('aria-selected') || isTabActive,
						'aria-expanded': isTabActive
					});
					$curTabPanel.attr({
						id: panelId,
						role: $curTabPanel.attr('role') || 'tabpanel',
						'aria-labelledby': $curTabPanel.attr('aria-labelledby') || $tab.attr('id'),
						'aria-hidden': !isTabActive
					});
				});
			},
			_bindOverflowEvents() {
				this.$element.on('scroll.tabs', ui.throttle(() => this._handleOverflowScroll(), 100));
				this.$overflowLeftButton.on('click.tabs', ui.debounce(() => {
					this._animateHorizontalScroll('left');
					return true;
				}, 250, true));
				this.$overflowRightButton.on('click.tabs', ui.debounce(() => {
					this._animateHorizontalScroll('right');
					return true;
				}, 250, true));
				this.overflowBound = true;
			},
			_centerTab() {
				if (this.overflow) {
					const $tab = this.$tabs.eq(this.activeIndex);
					const w = $tab.outerWidth(false);
					const offX = $tab.position().left;
					const curX = this.$element.scrollLeft();
					const pLeft = parseInt(this.$element.css('padding-left'), 10);
					const mLeft = parseInt($tab.css('margin-left'), 10);
					const ideal = (this.elementWidth - w) / 2;
					this.$element.animate({ scrollLeft: curX + offX - pLeft + mLeft - ideal }, 250);
				}
			},
			_checkOverflow() {
				this.overflow = this._getOverflow();
				if (this.overflow) {
					if (!this.overflowBound) {
						this._addOverflow();
					}
					this._handleOverflowScroll();
				} else if (this.overflowBound) {
					this._removeOverflow();
				}
			},
			_checkSubTabs() {
				const $tabContent = this.$tabContents.eq(this.activeIndex);
				const $subTabs = $tabContent.find('.tabs');
				if ($subTabs.length) {
					$subTabs.each((i, el) => {
						const $subTab = $(el);
						if ($subTab.data('tabsInstance')) {
							$subTab.tabs('resize');
						} else if ($subTab.data('auto-instantiate') !== 'off') {
							$subTab.tabs();
						}
					});
				}
			},
			_clearActiveTab() {
				this.$tabs
					.removeClass('tabActive active tabLast tabFirst')
					.attr({
						'aria-selected': 'false',
						'aria-expanded': 'false'
					});
				this.$tabContents
					.removeClass('tabActive active')
					.attr('aria-hidden', 'true');
			},
			_createOverflowElements() {
				const hasVerticalTabs = this.$element.hasClass('tabsVertical') ? ' hasVerticalTabs' : '';
				this.$element.wrap(`<div class="tabsScrollable${hasVerticalTabs}" />`);
				this.$overflow = this.$element.parent();
				this.$overflowLeftButton = $('<button class="icon iconArrowLeft tabsOverflowBtn" aria-hidden="true" tabindex="-1" type="button" />');
				this.$overflowRightButton = $('<button class="icon iconArrowRight tabsOverflowBtn" aria-hidden="true" tabindex="-1" type="button" />');
				this.$overflowLeftButton.insertBefore(this.$overflow);
				this.$overflowRightButton.insertAfter(this.$overflow);
			},
			destroy() {
				clearTimeout(this.delayedCheckOverflow);
				this._removeOverflow();
				this.$element.off('.tabs');
				this._removeAriaRoles();
				$(window).off(`.tabs.${this.tabsName}`);
				this.$element.removeData('tabsInstance'); /* Remove instance data from $element. */
			},
			_getActiveIndex() {
				const config = this.config;
				let activeIndex;
				if (config.activeIndex !== false) {
					activeIndex = config.activeIndex;
				} else if (config.active) {
					activeIndex = config.active - 1;
				} else {
					const tabActive = this.$element.find('.tabActive');
					activeIndex = tabActive.length ? tabActive.index() : 0;
				}
				return activeIndex;
			},
			/* Calculate the available width of $element. */
			_getElementWidth() {
				const outerWidth = this.$element.outerWidth(false);
				const paddingLeft = parseInt(this.$element.css('padding-left'), 10);
				const paddingRight = parseInt(this.$element.css('padding-right'), 10);
				return Math.max(0, outerWidth - (paddingLeft + paddingRight));
			},
			/* Check if tabs are wider than their container. */
			_getOverflow() {
				/* Don't bother with vertical tabs. */
				if (this.isVertical || this.$element.is(':hidden') || this.config.overrideOverflow) {
					return false;
				}
				return this.tabsWidth > this.elementWidth;
			},
			/* Calculate the cumulative width of the tab elements. */
			_getTabsWidth() {
				let totalWidth = 0;
				this.$tabs.each((i, el) => {
					totalWidth += $(el).outerWidth(true);
				});
				return totalWidth;
			},
			_getVertical() {
				const windowWidth = $(window).width();
				const notResponsive = !this.$element.hasClass('tabs480') && !this.$element.hasClass('tabs320');
				const responsiveOnTablet = this.$element.hasClass('tabs480') && (windowWidth >= 768);
				const responsiveOnMobile = this.$element.hasClass('tabs320') && (windowWidth >= 480);
				return this.$element.hasClass('tabsVertical') && (notResponsive || responsiveOnTablet || responsiveOnMobile);
			},
			_handleOverflowScroll() {
				if (this.$overflow) {
					const leftX = this.$element.scrollLeft();
					const tapBuffer = 5;
					/* Check for overflow on the right. */
					if (leftX < this.tabsWidth - this.elementWidth - tapBuffer) {
						this.$overflow.addClass('tabsOverflowRight');
						this.$overflowRightButton.addClass('tabsOverflowBtnVisible');
					} else {
						this.$overflow.removeClass('tabsOverflowRight');
						this.$overflowRightButton.removeClass('tabsOverflowBtnVisible');
					}
					/* Check for overflow on the left. */
					if (leftX > tapBuffer) {
						this.$overflow.addClass('tabsOverflowLeft');
						this.$overflowLeftButton.addClass('tabsOverflowBtnVisible');
					} else {
						this.$overflow.removeClass('tabsOverflowLeft');
						this.$overflowLeftButton.removeClass('tabsOverflowBtnVisible');
					}
				}
				return true;
			},
			_handleResize() {
				this.isVertical = this._getVertical();
				this._measureWidths();
				this._checkOverflow();
				return true;
			},
			_handleTabClick(e) {
				const $tab = $(e.currentTarget);
				/* Do not run if already the active tab */
				if ($tab.index() === this.activeIndex) {
					return false;
				}
				const config = this.config;
				const thisHref = $tab.attr('href');
				/* If the tab is supposed to go to a new page, exit here */
				if (!config.allowUrls && thisHref && thisHref.indexOf('#') !== 0 && thisHref.indexOf('javascript') !== 0) {
					window.location = thisHref;
					return false; /* Just in case so window doesn't redirect twice */
				}
				this.activeIndex = $tab.index();
				this._updateTabs();
				if (config.allowUrls) {
					return false;
				}
				return true;
			},
			_handleTabKeydown(e) {
				/* Keyboard accessibility, left/right arrow keys alternates tab focus, space activates tab giving focus to tabpanel (.tabContent) */
				const $tab = $(e.currentTarget);
				const key = e.which;
				if (key === ui.keyCodes.left) {
					$tab.prev().focus();
				} else if (key === ui.keyCodes.right) {
					$tab.next().focus();
				}
				return true;
			},
			_measureWidths() {
				this.tabsWidth = this._getTabsWidth();
				this.elementWidth = this._getElementWidth();
			},
			_removeAriaRoles() {
				this.$element.removeAttr('role');
				this.$tabs.removeAttr('role aria-controls aria-selected aria-expanded');
				this.$tabContents.removeAttr('role aria-labelledby aria-hidden');
			},
			_removeButtonClasses() {
				if (this.$tabs.hasClass('ancBtnL')) {
					this.$element.addClass('tabsConnected');
				}
				if (this.$tabs.hasClass('ancBtn')) {
					ui.warn('The ancBtn class should not be used on tabs.');
				}
				this.$tabs.removeClass('ancBtn ancBtnL ancBtnM ancBtnR silver');
			},
			_removeOverflow() {
				if (this.$overflow && this.$overflow.hasClass('tabsScrollable')) {
					this.$element.unwrap();
					this.$overflowLeftButton.remove();
					this.$overflowRightButton.remove();
					this.$overflow = false;
					this.$overflowLeftButton = false;
					this.$overflowRightButton = false;
					this._measureWidths();
				}
				this._removeOverflowEvents();
			},
			_removeOverflowEvents() {
				this.$element.off('scroll.tabs');
				this.overflowBound = false;
			},
			resize() {
				this._handleResize();
			},
			_setActiveContent() {
				this.$tabContents.eq(this.activeIndex)
					.addClass('tabActive active')
					.attr('aria-hidden', 'false');
				const config = this.config;
				if (config.onOpen && $.isFunction(config.onOpen)) {
					config.onOpen.apply(this.$element, [this.activeIndex, this.$tabContents.eq(this.activeIndex)]);
				}
				this._checkSubTabs();
			},
			_setActiveTab() {
				this.$tabs.eq(this.activeIndex)
					.addClass('tabActive active')
					.attr({
						'aria-selected': 'true',
						'aria-expanded': 'true'
					});
			},
			_triggerClickOnUrlTab() {
				const config = this.config;
				if (config && (!isNaN(parseInt(config.active, 10)) || !isNaN(parseInt(config.activeIndex, 10)))) {
					/* Only run activated tab if hash does not match another tab */
					if (!window.location.hash || !$(`.tab[data-label="${window.location.hash.replace('#', '')}"], .tab[href="${window.location.hash}"]`).length) {
						/* Run after all tabs initialized with setTimeout */
						setTimeout(() => {
							this.$tabs.eq(this.activeIndex).trigger('click.tabs');
						}, 100);
					}
				}
			},
			_updateTabs() {
				this._clearActiveTab();
				this._setActiveTab();
				this._setActiveContent();
				this._centerTab();
			}
		},
		staticProperties: {
			defaults: {
				active: false,
				activeIndex: false,
				allowUrls: false,
				onOpen: false, /* function (tabIndex, $tabContent) {} */
				overrideOverflow: false
			}
		}
	};
	/* Plugin definition */
	$.fn.tabs = function init(options, ...args) {
		/* If options is a string, we assume it refers to a method. */
		const isMethodCall = typeof options === 'string';
		let returnValue = this;
		this.each((i, el) => {
			const $el = $(el);
			if (isMethodCall) {
				const instance = $el.data('tabsInstance');
				if (!instance) {
					ui.warn(`Cannot call methods on tabs prior to initialization; attempted to call method "${options}".`);
					return false;
				}
				if (!$.isFunction(instance[options]) || options.indexOf('_') === 0) {
					ui.warn(`No such method "${options}" for tabs.`);
					return false;
				}
				const methodValue = instance[options](...args);
				if (methodValue !== instance && methodValue !== undefined) {
					returnValue = methodValue && methodValue.jquery ? returnValue.pushStack(methodValue.get()) : methodValue;
					return false;
				}
			} else {
				tabs.create($el, options);
			}
		});
		return returnValue;
	};
	/* Expose testable methods */
	$.tabs = {
		getTest: () => Object.assign({}, tabs)
	};
	$(() => {
		$('.tabs')
			/* Prevent double instantiation and auto-instantiation when it is turned off. */
			.filter((i, el) => !$(el).data('tabsInstance') && $(el).data('auto-instantiate') !== 'off')
			.tabs();
		if (window.location.hash) {
			$(`.tab[data-label="${window.location.hash.replace('#', '')}"], .tab[href="${window.location.hash}"]`).trigger('click.tabs'); /* Auto open url tab */
		}
	});
})(jQuery);
